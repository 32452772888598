/* eslint-disable @typescript-eslint/no-var-requires */
import {getFirebaseEnvironment} from "@buildwithflux/constants";
import {
    CachedDocumentEventRepository,
    CommandPersistenceService,
    createImmerPatchAdapter,
    createPartLibrary,
    createSnapshotLibrary,
} from "@buildwithflux/core";
import {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {
    ActionRecordSerde,
    AdapterSerdes,
    ClientFirestoreAdapter,
    DocumentSerde,
    ElementSerde,
    FirestoreCache,
    getFirebaseCompatApp,
    getFirebaseConfigForCurrentEnvironment,
    getFirebaseConfigForSpecificEnvironment,
    HandleMappingSerde,
    MetadataGenerator,
    MetadataWriteStrategy,
} from "@buildwithflux/firestore-compatibility-layer";
import {IDocumentMetaData, OrganizationUid, userJobParsers, UserUid} from "@buildwithflux/models";
import {BasicPlanEntitlementService, EntitlementContextService} from "@buildwithflux/plans";
import {
    ClientIdProvider,
    createFirestorePartRepository,
    createFirestorePartVersionRepository,
    createFirestoreStarredDocumentRepository,
    DocumentSnapshotRepository,
    FirestoreAccountFollowingRepository,
    FirestoreActionRecordRepository,
    FirestoreCommentRepository,
    FirestoreDocumentPricingRepository,
    FirestoreDocumentRepository,
    FirestoreEnterpriseRepository,
    FirestoreHandleRepository,
    FirestoreOrganizationPrivateMetadataRepository,
    FirestoreOrganizationRepository,
    FirestoreReviewRepository,
    FirestoreReviewCommentRepository,
    FirestoreSuggestionRepository,
    FirestoreUserAnalyticsRepository,
    FirestoreUserJobRepository,
    FirestoreUserPrivateMetadataRepository,
    FirestoreUserRepository,
    FirestoreUserUiStateRepository,
    StaticPartVersionRepository,
} from "@buildwithflux/repositories";
import {
    APP_VERSION,
    areWeTestingWithJest,
    defaultCdnConfig,
    silentLogger,
    withLazyInitialization,
} from "@buildwithflux/shared";
import {createDocumentService} from "@buildwithflux/solder-core";
import axios from "axios";
import {connectAuthEmulator, getAuth} from "firebase/auth";

import {FileOrganizationStorageHelper} from "../modules/account";
import {createAccountInformationStoreHook} from "../modules/account/AccountInformationStore";
import {FirebaseAuthService, FirebaseCurrentUserService, SignUpService} from "../modules/auth";
import {createAlgoliaSearchClientStoreHook} from "../modules/auth/state/algoliaClient";
import {createCurrentUserStoreHook} from "../modules/auth/state/currentUser";
import {createOrganizationStoreHook} from "../modules/auth/state/organization";
import {createTypesenseSearchClientStoreHook} from "../modules/auth/state/typesenseClient";
import {ClipboardService} from "../modules/clipboard/ClipboardService";
import {createUseRemoteUserIsTypingStateHook} from "../modules/comments/remoteUserTypingState";
import {createUseCommentStoreHook} from "../modules/comments/store";
import {PcbConnectivityGraph} from "../modules/connectivity/PcbConnectivityGraph";
import {DocumentObserver} from "../modules/observers/DocumentObserver";
import {createCreditReportStoreHook} from "../modules/payments/state/credit";
import {createPaymentUrlStoreHook} from "../modules/payments/state/url";
import {ClientPricingService} from "../modules/pricing";
import {FirestoreProfileHelper} from "../modules/profile";
import {
    createReactivitySubscriptions,
    createUseAllPcbNodes,
    createUseBaseDocument,
    createUsePcbNode,
    createUsePcbNodeBakedRules,
} from "../modules/reactivity";
import {createReviewStore} from "../modules/review/store";
import {PcbShapesStore} from "../modules/shapes";
import {
    FirestoreAxiosDocumentEventRepository,
    getSolderAxiosDefaults,
} from "../modules/solder/FirestoreAxiosDocumentEventRepository";
import {AnalyticsStorage} from "../modules/storage_engine/AnalyticsStorage";
import {BrowserStaticPartVersionStorageAdapter} from "../modules/storage_engine/cdn";
import {BrowserDocumentSnapshotStorageAdapter} from "../modules/storage_engine/cdn/BrowserDocumentSnapshotStorageAdapter";
import {NoOpDocumentSnapshotStorageAdapter} from "../modules/storage_engine/cdn/NoOpDocumentSnapshotStorageAdapter";
import {NoOpStaticPartVersionStorageAdapter} from "../modules/storage_engine/cdn/NoOpStaticPartVersionStorageAdapter";
import {CommentStorage} from "../modules/storage_engine/CommentStorage";
import AlgoliaConnector from "../modules/storage_engine/connectors/AlgoliaConnector";
import {TypesenseConnector} from "../modules/storage_engine/connectors/TypesenseConnector";
import {FirestoreDocumentStorage} from "../modules/storage_engine/DocumentStorage";
import {S3FileStorage} from "../modules/storage_engine/FileStorage";
import {AnalyticsEventHelper} from "../modules/storage_engine/helpers/AnalyticsEventHelper";
import {DocumentStorageHelper} from "../modules/storage_engine/helpers/DocumentStorageHelper";
import {PartStorageHelper} from "../modules/storage_engine/helpers/PartStorageHelper";
import {OrganizationProfileUpdater, UserProfileUpdater} from "../modules/storage_engine/helpers/ProfileUpdater";
import {UserStorageHelper} from "../modules/storage_engine/helpers/UserStorageHelper";
import {LRULocalStorage} from "../modules/storage_engine/LRULocalStorage";
import {PartStorage} from "../modules/storage_engine/PartStorage";
import {ProjectInviteStorage} from "../modules/storage_engine/ProjectInviteStorage";
import {UserStorage} from "../modules/storage_engine/UserStorage";
import {PcbBakedGoodsManager} from "../modules/stores/pcb/PcbBakedGoodsManager";
import {createUsePersistedDocumentUiStoreHook} from "../modules/stores/ui/persistedStore";
import {createUseDocumentUiStoreHook} from "../modules/stores/ui/store";
import {createUserStoreHook} from "../modules/stores/useUserStore";
import {ClientSuggestionService, ReduxAdapter} from "../modules/suggestions";
import {changeCaptureUnitOfWorkCreator} from "../modules/unit_of_work/ChangeCaptureUnitOfWork";
import {createUseUserUiStateStoreHook} from "../modules/user_ui_states/store";
import {UserCodeRuntimeWrapper} from "../modules/usercode_runtime/UserCodeRuntimeWrapper";
import {createReduxSolderAdapter} from "../redux/adapters/solder";
import {ReduxStoreServiceImpl} from "../redux/util/service";

import type {ContainerBindings} from "./container";
import {connectToFirebaseFunctions} from "./firebaseFunctions";

/**
 * Now we define a recipe for how to instantiate each container service
 */
export const defaultBindings: ContainerBindings = {
    shutdown(services) {
        return async () => {
            if ("logger" in services) {
                services.logger.debug("Beginning container shutdown");
            }

            if ("algoliaConnector" in services) {
                services.algoliaConnector.shutdown();
            }

            if ("typesenseConnector" in services) {
                services.typesenseConnector.shutdown();
            }

            if ("signUpService" in services) {
                services.signUpService.shutdown();
            }

            if ("currentUserService" in services) {
                await services.currentUserService.shutdown();
            }

            if ("firestoreForEmulator" in services) {
                await services.firestoreForEmulator.terminate();
            }

            if ("firestoreForCurrentEnvironment" in services) {
                await services.firestoreForCurrentEnvironment.terminate();
            }

            if ("firebaseAppForEmulator" in services) {
                await services.firebaseAppForEmulator.delete(); // This only renders this instance unusable
            }

            if ("firebaseAppForCurrentEnvironment" in services) {
                await services.firebaseAppForCurrentEnvironment.delete(); // This only renders this instance unusable
            }

            const shutDown = services as Record<string, unknown>;
            delete shutDown.algoliaConnector;
            delete shutDown.currentUserService;
            delete shutDown.firestoreForEmulator;
            delete shutDown.firestoreForCurrentEnvironment;
            delete shutDown.firebaseAppForEmulator;
            delete shutDown.firebaseAppForCurrentEnvironment;
        };
    },

    // Firebase
    firebaseAuth(services) {
        return getFirebaseEnvironment() === "emulated"
            ? services.firebaseAuthForEmulator
            : services.firebaseAuthForCurrentEnvironment;
    },
    firebaseAuthForEmulator({firebaseAppForEmulator}) {
        const connection = getAuth(firebaseAppForEmulator);
        connectAuthEmulator(connection, "http://127.0.0.1:9099");
        return connection;
    },
    firebaseAuthForCurrentEnvironment({firebaseAppForCurrentEnvironment}) {
        return getAuth(firebaseAppForCurrentEnvironment);
    },
    firebaseAppConfig() {
        return getFirebaseConfigForCurrentEnvironment();
    },
    firebaseApp(services) {
        return getFirebaseEnvironment() === "emulated"
            ? services.firebaseAppForEmulator
            : services.firebaseAppForCurrentEnvironment;
    },
    firebaseAppForEmulator() {
        const config = getFirebaseConfigForSpecificEnvironment("emulated");
        return getFirebaseCompatApp(config, config.projectId);
    },
    firebaseAppForCurrentEnvironment({firebaseAppConfig}) {
        return getFirebaseCompatApp(firebaseAppConfig, firebaseAppConfig.projectId);
    },
    firestore(services) {
        return getFirebaseEnvironment() === "emulated"
            ? services.firestoreForEmulator
            : services.firestoreForCurrentEnvironment;
    },
    firestoreForEmulator({firebaseAppForEmulator, firestoreSettings}) {
        const firestore = firebaseAppForEmulator.firestore();

        firestore.useEmulator("127.0.0.1", 8080);

        firestore.settings({
            // This is important to avoid runtime errors on whenever a property is
            // undefined. Previously, we ran all data thru a recursive function
            // removeUndefinedFields. The same setting is the common convention in
            // our cloud functions.
            ignoreUndefinedProperties: true,
            merge: true,

            ...firestoreSettings,
        });

        return firestore;
    },
    firestoreForCurrentEnvironment({firebaseAppForCurrentEnvironment, firestoreSettings}) {
        const firestore = firebaseAppForCurrentEnvironment.firestore();

        firestore.settings({
            // This is important to avoid runtime errors on whenever a property is
            // undefined. Previously, we ran all data thru a recursive function
            // removeUndefinedFields. The same setting is the common convention in
            // our cloud functions.
            ignoreUndefinedProperties: true,
            merge: true,

            ...firestoreSettings,
        });

        return firestore;
    },
    firestoreSettings() {
        return {
            experimentalAutoDetectLongPolling: true,
        }; // note: this binding is overridden in tests
    },
    functionsCentral(services) {
        return connectToFirebaseFunctions(services, "us-central1", getFirebaseEnvironment() === "emulated");
    },
    functionsWest(services) {
        return connectToFirebaseFunctions(services, "us-west2", getFirebaseEnvironment() === "emulated");
    },
    functionsWest1(services) {
        return connectToFirebaseFunctions(services, "us-west1", getFirebaseEnvironment() === "emulated");
    },

    // Auth stuff
    firebaseAuthService({firebaseAuth}) {
        return new FirebaseAuthService(firebaseAuth);
    },
    currentUserService({firebaseAuthService, userRepository, userPrivateMetadataRepository, logger}) {
        return new FirebaseCurrentUserService(
            firebaseAuthService,
            userRepository,
            userPrivateMetadataRepository,
            logger,
        );
    },
    algoliaConnector({functionsAdapter, currentUserService, logger}) {
        return new AlgoliaConnector(functionsAdapter, currentUserService, logger);
    },
    typesenseConnector({functionsAdapter, currentUserService, logger}) {
        return new TypesenseConnector(functionsAdapter, currentUserService, logger);
    },
    signUpService({firebaseAuthService, firestoreAdapter, logger}) {
        // Not ideal, but the LaunchDarklyConnector remains a global singleton, so the FeatureFlagStore cannot be added to the container easily
        const featureFlagsStore = require("../modules/common/hooks/featureFlags/useFeatureFlag").useFeatureFlags;
        return new SignUpService(firebaseAuthService, firestoreAdapter, featureFlagsStore, logger);
    },

    // Adapters
    firestoreAdapter({firestore, metadataGenerator, adapterSerdes, logger}) {
        return new ClientFirestoreAdapter(firestore, metadataGenerator, adapterSerdes, logger);
    },
    functionsAdapter({functionsCentral, functionsWest, functionsWest1}) {
        return new ClientFunctionsAdapter(functionsCentral, functionsWest, functionsWest1);
    },
    reduxSolderAdapter() {
        return createReduxSolderAdapter();
    },
    /**
     * Only connect to the real live CDN if we're not currently testing.
     */
    staticPartVersionStorageAdapter(services) {
        if (areWeTestingWithJest()) {
            return new NoOpStaticPartVersionStorageAdapter();
        } else {
            return new BrowserStaticPartVersionStorageAdapter(defaultCdnConfig, services.firebaseAuth);
        }
    },
    documentSnapshotStorageAdapter(services) {
        if (areWeTestingWithJest()) {
            return new NoOpDocumentSnapshotStorageAdapter();
        } else {
            return new BrowserDocumentSnapshotStorageAdapter(defaultCdnConfig, services.firebaseAuth);
        }
    },

    // Layout
    pcbBakedGoodsManager({logger, pcbShapesStore, documentService, pcbConnectivityGraph, reduxStoreService}) {
        return new PcbBakedGoodsManager(
            documentService,
            pcbShapesStore,
            pcbConnectivityGraph,
            reduxStoreService,
            logger,
        );
    },

    // Suggestions
    suggestionService({logger, reduxStoreService, suggestionRepository, currentUserService, clientIdProvider}) {
        const reduxAdapter = new ReduxAdapter(reduxStoreService);
        return new ClientSuggestionService(
            suggestionRepository,
            reduxAdapter,
            currentUserService,
            clientIdProvider,
            logger,
        );
    },

    // Document
    documentService({commandPersistenceService, documentEventRepository, cachedDocumentEventRepository}) {
        return createDocumentService(
            commandPersistenceService,
            documentEventRepository,
            cachedDocumentEventRepository,
            changeCaptureUnitOfWorkCreator,
        );
    },
    cachedDocumentEventRepository({documentEventRepository}) {
        return new CachedDocumentEventRepository(documentEventRepository);
    },
    commandPersistenceService({documentEventRepository, cachedDocumentEventRepository}) {
        return new CommandPersistenceService(documentEventRepository, cachedDocumentEventRepository);
    },

    // Reactivity
    reactivitySubscriptions({documentService}) {
        return createReactivitySubscriptions(documentService);
    },

    // Repos
    actionRecordRepository({firestoreAdapter, metadataGenerator, clientIdProvider}) {
        return new FirestoreActionRecordRepository(firestoreAdapter, metadataGenerator, clientIdProvider);
    },
    commentRepository({firestoreAdapter}) {
        return new FirestoreCommentRepository(firestoreAdapter);
    },
    userUiStateRepository({firestoreAdapter}) {
        return new FirestoreUserUiStateRepository(firestoreAdapter);
    },
    documentRepository({firestoreAdapter, functionsAdapter, logger}) {
        return new FirestoreDocumentRepository(firestoreAdapter, functionsAdapter, logger);
    },
    documentPricingRepository({firestoreAdapter, logger}) {
        return new FirestoreDocumentPricingRepository(firestoreAdapter, logger);
    },
    organizationRepository({firestoreAdapter, userRepository, organizationPrivateMetadataRepository, logger}) {
        return new FirestoreOrganizationRepository(
            firestoreAdapter,
            userRepository,
            organizationPrivateMetadataRepository,
            logger,
        );
    },
    organizationPrivateMetadataRepository({firestoreAdapter, logger}) {
        return new FirestoreOrganizationPrivateMetadataRepository(firestoreAdapter, logger);
    },
    partRepository({firestoreAdapter}) {
        return createFirestorePartRepository(firestoreAdapter);
    },
    reviewRepository({firestoreAdapter}) {
        return new FirestoreReviewRepository(firestoreAdapter);
    },
    reviewCommentRepository({firestoreAdapter}) {
        return new FirestoreReviewCommentRepository(firestoreAdapter);
    },
    partVersionRepository({firestoreAdapter}) {
        return createFirestorePartVersionRepository(firestoreAdapter);
    },
    starredDocumentRepository({firestoreAdapter, documentRepository}) {
        return createFirestoreStarredDocumentRepository(firestoreAdapter, documentRepository);
    },
    accountFollowingRepository({firestoreAdapter}) {
        return new FirestoreAccountFollowingRepository(firestoreAdapter);
    },
    staticPartVersionRepository({staticPartVersionStorageAdapter}) {
        return new StaticPartVersionRepository(staticPartVersionStorageAdapter);
    },
    documentSnapshotRepository({documentSnapshotStorageAdapter}) {
        return new DocumentSnapshotRepository(documentSnapshotStorageAdapter);
    },
    suggestionRepository({firestoreAdapter}) {
        return new FirestoreSuggestionRepository(firestoreAdapter);
    },
    userAnalyticsRepository({firestoreAdapter}) {
        return new FirestoreUserAnalyticsRepository(firestoreAdapter);
    },
    userJobRepository({firestoreAdapter, logger}) {
        return new FirestoreUserJobRepository(firestoreAdapter, logger, userJobParsers);
    },
    documentEventRepository({firestoreAdapter, firebaseAuth, logger}) {
        const axiosInstance = axios.create(getSolderAxiosDefaults());
        return new FirestoreAxiosDocumentEventRepository(firestoreAdapter, axiosInstance, firebaseAuth, logger);
    },

    // Serde
    adapterSerdes(container) {
        return withLazyInitialization<AdapterSerdes>(
            {
                documentSerdeFactory() {
                    return (strategy?: MetadataWriteStrategy<IDocumentMetaData>) =>
                        new DocumentSerde(container.metadataGenerator, strategy);
                },
                elementSerde() {
                    return container.elementSerde;
                },
                actionRecordSerde() {
                    return container.actionRecordSerde;
                },
                handleMappingSerde() {
                    return container.handleMappingSerde;
                },
            },
            undefined,
        );
    },
    actionRecordSerde() {
        return new ActionRecordSerde();
    },
    elementSerde({metadataGenerator}) {
        return new ElementSerde(metadataGenerator);
    },
    handleMappingSerde() {
        return new HandleMappingSerde();
    },

    // Storage
    analyticsStorage({
        reduxStoreService,
        currentUserService,
        userAnalyticsRepository,
        useOrganizationStore,
        documentService,
        logger,
    }) {
        return new AnalyticsStorage(
            reduxStoreService,
            currentUserService,
            userAnalyticsRepository,
            useOrganizationStore,
            documentService,
            logger,
        );
    },
    commentStorage({firestore, firestoreAdapter, logger, clientIdProvider}) {
        return new CommentStorage(firestore, firestoreAdapter, logger, clientIdProvider);
    },
    documentStorage({
        documentRepository,
        logger,
        functionsAdapter,
        analyticsStorage,
        firestore,
        firestoreAdapter,
        clientIdProvider,
        snapshotLibrary,
        partStorage,
    }) {
        return new FirestoreDocumentStorage(
            documentRepository,
            logger,
            functionsAdapter,
            analyticsStorage,
            firestore,
            firestoreAdapter,
            clientIdProvider,
            snapshotLibrary,
            partStorage,
        );
    },
    enterpriseRepository({firestoreAdapter, logger}) {
        return new FirestoreEnterpriseRepository(firestoreAdapter, logger);
    },
    fileStorage({analyticsStorage, functionsAdapter}) {
        return new S3FileStorage({}, analyticsStorage, functionsAdapter);
    },
    metadataGenerator({clientIdProvider}) {
        return new MetadataGenerator(APP_VERSION, clientIdProvider.clientId, "client");
    },
    partLibrary({partRepository, partVersionRepository, staticPartVersionRepository, documentRepository}) {
        return createPartLibrary(
            partRepository,
            partVersionRepository,
            staticPartVersionRepository,
            documentRepository,
        );
    },
    snapshotLibrary({documentSnapshotRepository, logger}) {
        return createSnapshotLibrary(documentSnapshotRepository, logger);
    },
    partStorage({partRepository, documentRepository, partVersionRepository, analyticsStorage, logger, partLibrary}) {
        return new PartStorage(
            partLibrary,
            documentRepository,
            partRepository,
            partVersionRepository,
            analyticsStorage,
            logger,
        );
    },
    projectInviteStorage({firestore, firestoreAdapter, logger, clientIdProvider}) {
        return new ProjectInviteStorage(firestore, firestoreAdapter, logger, clientIdProvider);
    },
    userStorage({firestore, firestoreAdapter, logger, analyticsStorage, clientIdProvider}) {
        return new UserStorage(firestore, firestoreAdapter, logger, analyticsStorage, clientIdProvider);
    },
    userRepository({firestoreAdapter, logger}) {
        return new FirestoreUserRepository(firestoreAdapter, logger);
    },
    handleRepository({firestoreAdapter}) {
        return new FirestoreHandleRepository(firestoreAdapter);
    },
    userPrivateMetadataRepository({firestoreAdapter}) {
        return new FirestoreUserPrivateMetadataRepository(firestoreAdapter);
    },
    lruLocalStorage() {
        return new LRULocalStorage();
    },

    // Plans
    entitlementContext({
        userRepository,
        organizationRepository,
        userPrivateMetadataRepository,
        organizationPrivateMetadataRepository,
    }) {
        return new EntitlementContextService(
            userRepository,
            organizationRepository,
            userPrivateMetadataRepository,
            organizationPrivateMetadataRepository,
        );
    },
    planEntitlement({entitlementContext, logger}) {
        return new BasicPlanEntitlementService(entitlementContext, logger);
    },

    // Helpers
    pcbShapesStore() {
        return new PcbShapesStore();
    },
    pcbConnectivityGraph() {
        return new PcbConnectivityGraph();
    },
    immerPatchAdapter() {
        return createImmerPatchAdapter();
    },
    analyticsEventHelper({analyticsStorage}) {
        return new AnalyticsEventHelper(analyticsStorage);
    },
    partStorageHelper({fileStorage}) {
        return new PartStorageHelper(fileStorage);
    },
    userCodeRuntimeWrapper() {
        return new UserCodeRuntimeWrapper();
    },
    userStorageHelper({fileStorage}) {
        return new UserStorageHelper(fileStorage);
    },
    documentStorageHelper({fileStorage}) {
        return new DocumentStorageHelper(fileStorage);
    },
    organizationStorageHelper({fileStorage}) {
        return new FileOrganizationStorageHelper(fileStorage);
    },
    profileHelper({handleRepository, userRepository, organizationRepository, functionsAdapter, logger}) {
        return new FirestoreProfileHelper(
            handleRepository,
            userRepository,
            organizationRepository,
            functionsAdapter,
            logger,
        );
    },
    userProfileUpdater({userStorage, currentUserService, logger}) {
        return (userUid: UserUid) => new UserProfileUpdater(userUid, userStorage, currentUserService, logger);
    },
    organizationProfileUpdater({organizationRepository, currentUserService, logger}) {
        return (organizationUid: OrganizationUid) =>
            new OrganizationProfileUpdater(organizationUid, organizationRepository, currentUserService, logger);
    },
    documentObserver({reduxStoreService, documentService, immerPatchAdapter}) {
        return new DocumentObserver(reduxStoreService, documentService, immerPatchAdapter);
    },

    // Store hook factories
    useAccountInformationStore({userRepository, organizationRepository, enterpriseRepository}) {
        return createAccountInformationStoreHook(userRepository, organizationRepository, enterpriseRepository);
    },
    useUserStore({currentUserService, analyticsStorage, userStorage}) {
        return createUserStoreHook(currentUserService, analyticsStorage, userStorage);
    },
    useCurrentUserStore({currentUserService, firebaseAuthService}) {
        return createCurrentUserStoreHook(currentUserService, firebaseAuthService, silentLogger);
    },
    useAlgoliaSearchClientStore({algoliaConnector}) {
        return createAlgoliaSearchClientStoreHook(algoliaConnector);
    },
    useTypesenseSearchClientStore({typesenseConnector}) {
        return createTypesenseSearchClientStoreHook(typesenseConnector);
    },
    useCommentStore({currentUserService, commentRepository, logger}) {
        return createUseCommentStoreHook(currentUserService, commentRepository, logger);
    },
    useUserUiStateStore({currentUserService, userUiStateRepository, logger}) {
        return createUseUserUiStateStoreHook(currentUserService, userUiStateRepository, logger);
    },
    useRemoteUserIsTypingState(_services) {
        return createUseRemoteUserIsTypingStateHook();
    },
    useDocumentUiStore(_services) {
        return createUseDocumentUiStoreHook();
    },
    usePersistedDocumentUiStore(_services) {
        return createUsePersistedDocumentUiStoreHook();
    },
    useOrganizationStore({currentUserService, organizationRepository, enterpriseRepository, functionsAdapter}) {
        return createOrganizationStoreHook(
            currentUserService,
            organizationRepository,
            enterpriseRepository,
            functionsAdapter,
            silentLogger,
        );
    },
    usePaymentUrlStore({functionsAdapter, logger}) {
        return createPaymentUrlStoreHook(functionsAdapter, logger);
    },
    usePcbNode({reactivitySubscriptions}) {
        return createUsePcbNode(reactivitySubscriptions);
    },
    usePcbNodeBakedRules({reactivitySubscriptions}) {
        return createUsePcbNodeBakedRules(reactivitySubscriptions);
    },
    useAllPcbNodes({reactivitySubscriptions}) {
        return createUseAllPcbNodes(reactivitySubscriptions);
    },
    useBaseDocument({reactivitySubscriptions, reduxStoreService}) {
        return createUseBaseDocument(reactivitySubscriptions, reduxStoreService);
    },
    useCreditReportStore({functionsAdapter, currentUserService, logger}) {
        return createCreditReportStoreHook(functionsAdapter, currentUserService, logger, silentLogger);
    },
    useReviewStore(_services) {
        return createReviewStore();
    },

    // Other
    logger() {
        return console;
    },
    reduxStoreService() {
        return new ReduxStoreServiceImpl();
    },
    clipboardService() {
        return new ClipboardService();
    },

    // Misc
    clientIdProvider() {
        return new ClientIdProvider();
    },

    pricingDataCache({firestoreAdapter}) {
        return new FirestoreCache(firestoreAdapter.pricingDataCacheCollection());
    },

    pricingService({pricingDataCache, functionsAdapter, analyticsStorage, logger}) {
        return new ClientPricingService(pricingDataCache, functionsAdapter, analyticsStorage, logger);
    },
};
