import {Routes, getProfileUrl} from "@buildwithflux/core";
import {FirebaseUser} from "@buildwithflux/firestore-compatibility-layer";
import {ICurrentUserService, UserStateType} from "@buildwithflux/models";
import {useMemo, useState, useEffect} from "react";
import {Redirect, RouteProps} from "react-router-dom";

import {useFluxServices} from "../../../injection/hooks";
import {useCurrentUserAuthenticationState} from "../../../modules/auth/state/currentUser";
import FullPageLoader from "../../common/components/loader/FullPageLoader";
import NetworkConnectivityErrorPage from "../errors/500/NetworkConnectivityErrorPage";

import {RouteWithErrorBoundary} from "./RouteWithErrorBoundary";

type Route = string;

interface IConditionalRouteProps extends RouteProps {
    /**
     * If true, the route is a sign up route
     */
    isSignUp?: boolean;

    /**
     * If true, the route is a log in route
     */
    isLogIn?: boolean;

    /**
     * If true, the route should only be available to non-anonymous fully-authenticated users (and anonymous or
     * partially-authenticated users will be redirected away)
     */
    isProtected?: boolean;
}

function getRouteForState(
    state: UserStateType,
    currentUserService: ICurrentUserService<FirebaseUser>,
    {isSignUp, isLogIn, isProtected}: IConditionalRouteProps,
): Route | undefined {
    // If the user is partially-authenticated, they should be redirected to the sign-up page when they try to sign up
    // or log in. The SignUp page can cope with partially-authenticated users and complete the sign-up process
    if (state === UserStateType.enum.partiallyAuthenticated && !isSignUp) {
        return Routes.SIGN_UP;
    }

    const currentUser = currentUserService.getCurrentUser();

    // If the user is fully authenticated, they should be redirected to their profile when they try to sign up or log in
    if (
        (isSignUp || isLogIn) &&
        currentUser &&
        !currentUser.isAnonymous &&
        state === UserStateType.enum.authenticated
    ) {
        return getProfileUrl(currentUser);
    }

    // If the route is protected and the user reaches an unauthenticated final state, redirect to sign up or log in
    if (isProtected) {
        if (state === UserStateType.enum.anonymous) {
            return Routes.SIGN_UP;
        }
        if (state === UserStateType.enum.loggedOut) {
            return Routes.LOG_IN;
        }
    }

    return undefined;
}

/**
 * Note that practically, when this class is used in App.tsx, in the React Router, that usages is protected by a
 * blockUntilAuthReady HOF wrapper, which ensures the Firebase user is always set, and thus that the user state is
 * not LoggedOut, but Anonymous or Authenticated or PartiallyAuthenticated.
 *
 * But note that this component is responsible for not following any redirections caused by user state unless
 * Firestore is available, so that we don't redirect the user to a partiallyAuthenticated signup form just because
 * we can't contact Firestore
 */
export default function ConditionalRoute(props: IConditionalRouteProps) {
    const {isSignUp, isLogIn, ...rest} = props;
    const {logger, currentUserService, firestoreAdapter} = useFluxServices();

    const state = useCurrentUserAuthenticationState();
    const [loading, setLoading] = useState<boolean>(true);
    const [firestoreConnectivityError, setFirestoreConnectivityError] = useState<Error | undefined>(undefined);

    const routeToRedirectTo = useMemo(() => {
        const route = getRouteForState(state, currentUserService, {
            isSignUp: false,
            isLogIn: false,
            isProtected: false,
            ...props,
        });
        return route;
    }, [state, currentUserService, props]);

    useEffect(() => {
        if (routeToRedirectTo) {
            firestoreAdapter
                .ping()
                .then(() => {
                    setFirestoreConnectivityError(undefined);
                })
                .catch((error) => {
                    logger.error("ConditionalRoute: Firestore is not available");
                    setFirestoreConnectivityError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [routeToRedirectTo]);

    if (routeToRedirectTo) {
        if (loading) {
            return <FullPageLoader />;
        }

        if (firestoreConnectivityError) {
            logger.error("ConditionalRoute: Firestore is not available, so we are not redirecting the user");
            return <NetworkConnectivityErrorPage />;
        }

        logger.debug(`ConditionalRoute is redirecting the user`, {
            props,
            state,
            routeToRedirectTo,
        });

        return <Redirect to={routeToRedirectTo} />;
    } else {
        return <RouteWithErrorBoundary {...rest} />;
    }
}
