import {
    ICommentData,
    SuggestionService,
    TentativeSuggestion,
    devAssert,
    intoAcceptedSuggestion,
    intoFailedSuggestion,
} from "@buildwithflux/core";
import {isCalSuggestion, CommandSuggestion, CopilotSuggestion, Command} from "@buildwithflux/models";
import {ClientIdProvider, SubscriptionManager, SuggestionRepository} from "@buildwithflux/repositories";
import {Logger, areWeTestingWithJest} from "@buildwithflux/shared";

import {ReduxStoreService} from "../../redux/util/service";
import {CurrentUserService} from "../auth";
import {FluxLogger} from "../storage_engine/connectors/LogConnector";

import {ApplySuggestionResult} from "./models";
import {applySuggestion} from "./thunks";

/**
 * In-memory storage for suggestions.
 */
type DocumentSuggestions = Map<CopilotSuggestion["uid"], CopilotSuggestion>;

/**
 * In the idle state, the suggestion service isn't listening to suggestions for any documents.
 */
type Idle = {
    type: "idle";
};

function idle(): Idle {
    return {
        type: "idle",
    };
}

/**
 * In the listening state, the suggestion service is listening to suggestions for a particular document.  When it
 * receives new suggestions, it will update its internal state and also apply any suggestions that are automatically
 * to be applied.
 */
type Listening = {
    type: "listening";
    currentDocumentUid: string;
    currentUserUid: string;
    suggestions: DocumentSuggestions;
    suggestionUnsubscriber: () => void;
};

type ServiceState = Idle | Listening;

/**
 * Class that actually does the work of applying suggestions to a document.
 */
export class ReduxAdapter {
    constructor(protected readonly storeService: ReduxStoreService) {}

    /**
     * Use redux to apply a suggestion to the current document.
     */
    public async applySuggestion(suggestion: CommandSuggestion): Promise<ApplySuggestionResult> {
        const resultingActionRecordUid = await this.storeService.getStore().dispatch(applySuggestion(suggestion));
        return resultingActionRecordUid;
    }
}

export class ClientSuggestionService extends SuggestionService {
    private state: ServiceState = idle();
    private readonly suggestionsByCommentUid = new Map<ICommentData["uid"], CopilotSuggestion>();
    private readonly subscriptionManager = new SubscriptionManager<ICommentData["uid"], CopilotSuggestion>();

    constructor(
        repository: SuggestionRepository,
        private readonly adapter: ReduxAdapter,
        private readonly currentUserService: CurrentUserService,
        private readonly clientIdProvider: ClientIdProvider,
        private readonly logger: Logger,
    ) {
        super(repository);
    }

    /**
     * Cleanly shutdown the service.  This does not destroy it in memory - it simply stops subscriptions and removes all
     * data related to the current document.  If there is no current document, this is a no-op.
     */
    public shutdown(): void {
        if (this.state.type === "listening") {
            this.state.suggestionUnsubscriber();
            this.state = idle();
        }
    }

    /**
     * Set the current document UID.  This will start the listening loop that listens for suggestions for the new document, and preheats
     * the local cache with any suggestions that are already present.
     */
    public setCurrentDocumentUid(documentUid: string): void {
        if (this.state.type === "listening") {
            // Don't do anything if we're already in the correct state.
            if (this.state.currentDocumentUid === documentUid) return;
            // We're already listening to a different document - shut down before starting a new listen loop.
            this.shutdown();
        }

        this.startListening(documentUid);
    }

    /**
     * Apply a suggestion to the current document.  Returns a promise that resolves when
     * the suggestion has been applied and updated in the database.
     */
    public async applySuggestion(suggestion: CommandSuggestion): Promise<void> {
        // If somebody is trying to apply a suggestion that's already been applied, that's a logical error.  It's safe at prod
        // time because we'll just ignore it, but it suggests something isn't quite right.
        // QUESTION: is it appropriate to test this case?  The devAssert should help prevent issues, but it feels unsatisfying because
        // it's pushing the responsibility to acceptance testing to catch this.  So I'm using a check on the test environment as _well_,
        // but I'm not sure if that's the right thing to do.
        if (!areWeTestingWithJest()) {
            devAssert(
                suggestion.type !== "acceptedSuggestion",
                `Logic error: CommandSuggestion was applied after it has already been accepted: ${JSON.stringify(
                    suggestion,
                )})}`,
                FluxLogger,
            );
        }
        if (suggestion.type === "suggestion") {
            return this.doApplySuggestion(suggestion);
        }
    }

    /**
     * Create a subscription channel for suggestion events.
     */
    public subscribeToSuggestionForComment(
        commentUid: string,
        onChange: (suggestion: CopilotSuggestion | undefined) => void,
    ): () => void {
        return this.subscriptionManager.addSubscription(commentUid, onChange);
    }

    /**
     * Get all suggestions for a comment.
     */
    public getSuggestionForComment(commentUid: string): CopilotSuggestion | undefined {
        return this.suggestionsByCommentUid.get(commentUid);
    }

    /**
     * Generic handler for suggestion events.
     */
    private onNewSuggestionData(event: "create" | "update", suggestion: CopilotSuggestion): void {
        if (this.state.type !== "listening") {
            throw new Error(
                `Invariant violated: ClientSuggestionService received firestore update while in state other than listening: ${this.state.type}`,
            );
        }

        const autoSuggestionsToProcess: TentativeSuggestion<Command[]>[] = [];
        switch (event) {
            case "update":
            case "create":
                // Not supported for CAL actions, which are more involved
                if (isCalSuggestion(suggestion)) break;

                this.state.suggestions.set(suggestion.uid, suggestion);
                // Will ignore any suggestion that is not in the tentative state or that cannot be applied on this client.
                if (event === "create" && suggestion.type === "suggestion" && this.canAutoApply(suggestion)) {
                    autoSuggestionsToProcess.push(suggestion);
                }
        }
        if (suggestion.anchor.type === "message") {
            const key = suggestion.anchor.messageDescriptor.commentUid;
            this.suggestionsByCommentUid.set(key, suggestion);
            this.subscriptionManager.notify(key, suggestion);
        }
        if (autoSuggestionsToProcess.length > 0) {
            // TODO: what to do about this fire-and-forget promise?
            this.applyAutoSuggestions(autoSuggestionsToProcess);
        }
    }

    /**
     * Handler for suggestion deletion events.
     */
    private onSuggestionDeleted(suggestionUid: string): void {
        if (this.state.type !== "listening") {
            throw new Error(
                `Invariant violated: ClientSuggestionService received firestore update while in state other than listening: ${this.state.type}`,
            );
        }

        this.state.suggestions.delete(suggestionUid);
    }

    /**
     * Check if a suggestion can be auto-applied.
     */
    private canAutoApply(suggestion: CommandSuggestion): boolean {
        // If it explicitly requires user consent, obviously we can't auto-apply it.
        if (suggestion.acceptance.type !== "automaticAcceptance") return false;
        // If it has already been accepted, we can't auto-apply it.
        if (suggestion.type === "acceptedSuggestion") return false;
        // Otherwise, we can only auto-apply if the suggestion was originated for the current user.
        return (
            suggestion.acceptance.originatingUserId === this.currentUserService.getCurrentUser()?.uid &&
            suggestion.acceptance.originatingClientId === this.clientIdProvider.clientId
        );
    }

    /**
     * Apply auto-suggestions.  Note that this assumes that all suggestions are auto-applyable.
     */
    private async applyAutoSuggestions(suggestions: TentativeSuggestion<Command[]>[]): Promise<void> {
        for (const suggestion of suggestions) {
            devAssert(
                this.canAutoApply(suggestion),
                `Invariant violated: suggestion in auto-apply batch was not auto-applyable: ${JSON.stringify(
                    suggestion,
                )}`,
                FluxLogger,
            );
            await this.doApplySuggestion(suggestion);
        }
    }

    /**
     * Listen for CRUD events from the repository.
     */
    private startListening(documentUid: string): void {
        devAssert(
            this.state.type === "idle",
            `Invariant violated: ClientSuggestionService should be in idle state before listening, but is instead in state with type: ${this.state.type}`,
            FluxLogger,
        );
        this.logger.debug("ClientSuggestionService: starting listen loop");
        const currentUserUid = this.currentUserService.getCurrentUser()?.uid;
        devAssert(
            currentUserUid != null,
            `Invariant violated: Current user should never be null when listen loop started for ClientSuggestionService`,
            FluxLogger,
        );
        if (currentUserUid == null) {
            // TODO: log or something?  Technically we should be able to proceed here - we just won't be able to ever respond to
            // auto-apply suggestions.  We'll leave it like this for now but need to revisit.
            this.logger.debug("current user is null, doing nothing");
            return;
        }

        const unsubscriber = this.repository.subscribeToSuggestionsForDocument(documentUid, {
            onCreate: (suggestion) => this.onNewSuggestionData("create", suggestion),
            onUpdate: (suggestion) => this.onNewSuggestionData("update", suggestion),
            onDelete: (suggestion) => this.onSuggestionDeleted(suggestion),
        });

        this.state = {
            type: "listening",
            currentDocumentUid: documentUid,
            currentUserUid,
            suggestions: new Map(),
            suggestionUnsubscriber: unsubscriber,
        };
    }

    /**
     * Apply the suggestion to the document, and record the result in the database.
     */
    private async doApplySuggestion(suggestion: CommandSuggestion): Promise<void> {
        const applyResult = await this.adapter.applySuggestion(suggestion);
        if (applyResult.type === "failure") {
            /**
             * Otherwise, something went wrong.
             */
            const failureState = intoFailedSuggestion(suggestion, Date.now(), applyResult.reason);
            await this.repository.save(failureState);
            return;
        }
        const acceptedSuggestion = intoAcceptedSuggestion(suggestion, Date.now(), applyResult.appliedAsActionRecordUid);
        await this.repository.save(acceptedSuggestion);
    }
}
