import {areWeInStorybook} from "@buildwithflux/shared";
import {onIdTokenChanged} from "firebase/auth";
import {useCallback, useEffect, useState} from "react";

import {useFluxServices} from "../../../injection/hooks";

export function useCurrentUserIdToken(): string | undefined {
    const {firebaseAuth, useCurrentUserStore} = useFluxServices();
    const currentFirebaseUser = useCurrentUserStore((state) => state.firebaseCurrentUser);

    const [possibleIdToken, setIdToken] = useState<string | undefined>();

    const tryToGetLatestToken = useCallback(() => {
        if (areWeInStorybook()) return; // Prevents Storybook component error in CommentView as of writing this
        if (!currentFirebaseUser) return;
        currentFirebaseUser.getIdToken().then((token) => setIdToken(token));
    }, [currentFirebaseUser]);

    useEffect(() => {
        tryToGetLatestToken();

        const unsub = onIdTokenChanged(firebaseAuth, () => {
            tryToGetLatestToken();
        });

        return unsub;
    }, [firebaseAuth, tryToGetLatestToken]);

    return possibleIdToken;
}
