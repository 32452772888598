import {PartPublishingStatus} from "@buildwithflux/core";
import {IPartVersionData} from "@buildwithflux/models";
import {createAction} from "@reduxjs/toolkit";

/**
 * NOTE: not to be confused with setPartVersionDataCache!
 */
export const setPartVersionData = createAction("setPartVersionData", (partVersionData: IPartVersionData) => {
    return {
        payload: {
            partVersionData,
        },
    };
});

export const setPartPublishingStatus = createAction("setPartPublishingStatus", (status: PartPublishingStatus) => {
    return {
        payload: {
            status,
        },
    };
});

export const clearPartVersionData = createAction("clearPartVersionData");
