import {
    FluxInternalOrganizationUid,
    FluxOrganizationUid,
    getPaymentPlanCategory,
    isPaidPaymentPlanCategory,
} from "@buildwithflux/core";
import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {
    CurrentUser,
    Enterprise,
    EnterpriseFullyDecoratedOrganizationMember,
    EnterpriseRole,
    EnterpriseUid,
    EnterpriseWithDefaultOrganizationAndMetadata,
    FullyDecoratedOrganizationMember,
    IUserData,
    NonEnterpriseFullyDecoratedOrganizationMember,
    OrganizationMember,
    OrganizationPrivateMetadata,
    OrganizationRole,
    OrganizationUid,
    UserUid,
} from "@buildwithflux/models";
import {EnterpriseRepository, OrganizationRepository} from "@buildwithflux/repositories";
import {areWeTestingWithJest, isDevEnv, Logger, silentLogger} from "@buildwithflux/shared";
import {compact} from "lodash";
import {useEffect, useState} from "react";
import {create, StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";

import {useFluxServices} from "../../../injection/hooks";
import {CurrentUserService} from "../types";

import {useCurrentUser} from "./currentUser";

type OrganizationStoreApi = {
    unsubscribe: () => void;
    setCurrentPageOrganizationContext(organizationUid: OrganizationUid | undefined): void;
    loadSharedWorkspaces(otherUserUid: UserUid): void;
};

type OrganizationState = {
    nextUserUid: UserUid | undefined;
    currentUserUid: UserUid | undefined;

    nextOrganizationMembershipSentinel: string | undefined;
    currentOrganizationMembershipSentinel: string | undefined;

    memberships: FullyDecoratedOrganizationMember[];

    enterpriseMemberships: EnterpriseFullyDecoratedOrganizationMember[];
    nonEnterpriseMemberships: NonEnterpriseFullyDecoratedOrganizationMember[];

    finishedLoading: boolean;

    currentPageOrganizationContext: OrganizationUid | undefined;

    sharedWorkspacesCache: Record<UserUid, {organizationUids: OrganizationUid[]; expiresAt: number}>;
};

const baseEmptyState: Omit<OrganizationState, "finishedLoading"> = {
    nextUserUid: undefined,
    currentUserUid: undefined,
    nextOrganizationMembershipSentinel: undefined,
    currentOrganizationMembershipSentinel: undefined,
    memberships: [],
    enterpriseMemberships: [],
    nonEnterpriseMemberships: [],
    currentPageOrganizationContext: undefined,
    sharedWorkspacesCache: {},
};

export type OrganizationStoreState = OrganizationStoreApi & OrganizationState;
export type UseOrganizationStore = UseBoundStore<StoreApi<OrganizationStoreState>>;

export const createOrganizationStoreHook = (
    currentUserService: CurrentUserService,
    organizationRepository: OrganizationRepository,
    enterpriseRepository: EnterpriseRepository,
    functionsAdapter: FunctionsAdapter,
    logger: Logger = silentLogger,
): UseOrganizationStore => {
    return create<OrganizationStoreState>()(
        devtools(
            (set, get) => {
                async function fetchEnterpriseDetails(
                    enterpriseUids: Set<EnterpriseUid>,
                ): Promise<Record<EnterpriseUid, EnterpriseWithDefaultOrganizationAndMetadata | undefined>> {
                    return Object.fromEntries(
                        await Promise.all(
                            [...enterpriseUids.values()].map(
                                async (
                                    enterpriseUid,
                                ): Promise<
                                    [EnterpriseUid, EnterpriseWithDefaultOrganizationAndMetadata | undefined]
                                > => {
                                    return [
                                        enterpriseUid,
                                        await enterpriseRepository.getByUidWithDefaultOrganizationAndMetadata(
                                            enterpriseUid,
                                        ),
                                    ];
                                },
                            ),
                        ),
                    );
                }

                const userChangeUnsubscriber = currentUserService.subscribeToUserChanges((currentUser: CurrentUser) => {
                    const newUserUid: UserUid | undefined = currentUser.user?.uid;
                    const newOrganizationMembershipSentinel = currentUser.user?.organization_membership_sentinel;

                    if (!newUserUid) {
                        set(
                            {
                                ...baseEmptyState,
                                finishedLoading: true,
                            },
                            false,
                            "userChangeToUnauthenticated",
                        );
                        return;
                    }

                    if (
                        get().nextUserUid !== newUserUid ||
                        get().nextOrganizationMembershipSentinel !== newOrganizationMembershipSentinel
                    ) {
                        set(
                            {
                                nextUserUid: newUserUid,
                                nextOrganizationMembershipSentinel: newOrganizationMembershipSentinel,
                                sharedWorkspacesCache: {},
                            },
                            false,
                            "startedLoadingOrganizationMemberships",
                        );

                        if (currentUser.user && !currentUser.user.isAnonymous) {
                            void organizationRepository
                                .getAllDecoratedOrganizationMembersForCurrentUser(currentUserService)
                                .then(async (initialMemberships) => {
                                    const enterpriseUids: Set<EnterpriseUid> = new Set(
                                        initialMemberships
                                            .map((m) => m.enterpriseUid)
                                            .filter((m): m is EnterpriseUid => !!m),
                                    );

                                    const enterprises = await fetchEnterpriseDetails(enterpriseUids);

                                    if (
                                        get().nextUserUid !== newUserUid ||
                                        get().nextOrganizationMembershipSentinel !== newOrganizationMembershipSentinel
                                    ) {
                                        // we took too long to get the enterprise details and the user has changed - this becomes a noop, because we don't want to overwrite the new user's memberships out-of-order
                                        return;
                                    }

                                    const memberships = compact(
                                        initialMemberships.map(
                                            (membership): FullyDecoratedOrganizationMember | undefined => {
                                                const enterpriseUid = membership.enterpriseUid;

                                                if (enterpriseUid) {
                                                    const enterprise = enterprises[enterpriseUid];

                                                    if (!enterprise) {
                                                        logger.warn(
                                                            `Could not find enterprise details for loaded membership: ${membership.organizationUid} of ${enterpriseUid}`,
                                                        );
                                                        return undefined;
                                                    }

                                                    return {
                                                        ...membership,
                                                        enterpriseUid,
                                                        enterprise,
                                                    };
                                                }

                                                return {
                                                    ...membership,
                                                    enterpriseUid: undefined,
                                                    enterprise: undefined,
                                                };
                                            },
                                        ),
                                    );

                                    const enterpriseMemberships = memberships.filter(
                                        (membership): membership is EnterpriseFullyDecoratedOrganizationMember =>
                                            !!membership.enterpriseUid,
                                    );

                                    const nonEnterpriseMemberships = memberships.filter(
                                        (membership): membership is NonEnterpriseFullyDecoratedOrganizationMember =>
                                            !membership.enterpriseUid,
                                    );

                                    set(
                                        {
                                            currentUserUid: newUserUid,
                                            currentOrganizationMembershipSentinel: newOrganizationMembershipSentinel,
                                            memberships,
                                            enterpriseMemberships,
                                            nonEnterpriseMemberships,
                                            finishedLoading: true,
                                        },
                                        false,
                                        "finishedLoading",
                                    );
                                })
                                .catch((error) => {
                                    logger.error("Error fetching organization memberships", error);
                                    throw error;
                                });
                        }
                    }
                });

                return {
                    // API
                    unsubscribe() {
                        userChangeUnsubscriber();
                    },
                    setCurrentPageOrganizationContext(organizationUid: OrganizationUid | undefined) {
                        set(
                            {
                                currentPageOrganizationContext: organizationUid,
                            },
                            false,
                            "setCurrentPageOrganizationContext",
                        );
                    },
                    async loadSharedWorkspaces(otherUserUid: UserUid): Promise<void> {
                        const {currentUserUid, sharedWorkspacesCache} = get();

                        if (!currentUserUid) {
                            return;
                        }

                        if (
                            sharedWorkspacesCache[otherUserUid] &&
                            (sharedWorkspacesCache[otherUserUid]?.expiresAt ?? 0) > Date.now()
                        ) {
                            return;
                        }

                        const {organizationUids} = await functionsAdapter.getSharedWorkspaces({otherUserUid});
                        const expiresAt = Date.now() + 1000 * 60 * 5; // 5 minutes from now

                        set(
                            (state) => ({
                                sharedWorkspacesCache: {
                                    ...state.sharedWorkspacesCache,
                                    [otherUserUid]: {
                                        organizationUids,
                                        expiresAt,
                                    },
                                },
                            }),
                            false,
                            "addSharedWorkspacesToCache",
                        );
                    },

                    // State
                    ...baseEmptyState,
                    finishedLoading: false,
                };
            },
            {name: "OrganizationStore", enabled: isDevEnv() && !areWeTestingWithJest()},
        ),
    );
};

/**
 * Gets API methods for the organization store
 */
export function useOrganizationStoreApi(): OrganizationStoreApi {
    const {useOrganizationStore} = useFluxServices();

    const unsubscribe = useOrganizationStore((state) => state.unsubscribe);
    const setCurrentPageOrganizationContext = useOrganizationStore((state) => state.setCurrentPageOrganizationContext);
    const loadSharedWorkspaces = useOrganizationStore((state) => state.loadSharedWorkspaces);

    return {
        unsubscribe,
        setCurrentPageOrganizationContext,
        loadSharedWorkspaces,
    };
}

/**
 * Gets the organization memberships of the current user
 *
 * These become available some time after the current user changes (e.g. after a login). It is NOT guaranteed
 * that the list of memberships is synced to the current user at any given point in time. We only guarantee
 * that they will become eventually consistent.
 */
export function useCurrentOrganizationMemberships(): FullyDecoratedOrganizationMember[] {
    return useFluxServices().useOrganizationStore((state) => state.memberships);
}

/**
 * Gets the enterprise memberships of the current user
 */
export function useCurrentEnterpriseOrganizationMemberships(): EnterpriseFullyDecoratedOrganizationMember[] {
    return useFluxServices().useOrganizationStore((state) => state.enterpriseMemberships);
}

/**
 * Gets the non-enterprise memberships of the current user
 */
export function useCurrentNonEnterpriseOrganizationMemberships(): NonEnterpriseFullyDecoratedOrganizationMember[] {
    return useFluxServices().useOrganizationStore((state) => state.nonEnterpriseMemberships);
}

/**
 * Whether the current user is a member of the "flux" organization
 */
export function useIsFluxOrganizationMember(): boolean {
    return useCurrentOrganizationMemberships().some(
        (organization) => organization.organizationUid === FluxOrganizationUid,
    );
}
/**
 * Whether the current user is a member of the "flux-internal" organization. Can be used for internal-only features.
 *
 * Should _not_ be used to protect ultra-sensitive features, only for general use.
 */
export function useIsFluxInternalOrganizationMember(): boolean {
    return useCurrentOrganizationMemberships().some(
        (organization) => organization.organizationUid === FluxInternalOrganizationUid,
    );
}

export function useCurrentOrganizationMembershipsFinishedLoading(): boolean {
    return useFluxServices().useOrganizationStore((state) => state.finishedLoading);
}

/**
 * The current user's organization membership in the specified organization UID, if given
 *
 * The parameter is optional, so we can pass optional properties, like baseDocument.organization_owner_uid
 */
export function useCurrentOrganizationMembershipForOrganization(
    organizationUid: OrganizationUid | undefined,
): FullyDecoratedOrganizationMember | undefined {
    const currentMemberships = useCurrentOrganizationMemberships();

    if (!organizationUid) {
        return undefined;
    }

    return currentMemberships.find((m) => m.organizationUid === organizationUid);
}

export function useAllCurrentOrganizationMembershipsForOrganizationUids(
    organizationUids: OrganizationUid[],
): FullyDecoratedOrganizationMember[] {
    const currentMemberships = useCurrentOrganizationMemberships();

    if (!organizationUids.length) {
        return [];
    }

    return currentMemberships.filter((m) => organizationUids.includes(m.organizationUid));
}

export function useCurrentOrganizationMembershipForEnterprise(
    enterpriseUid: EnterpriseUid | undefined,
): FullyDecoratedOrganizationMember | undefined {
    const currentMemberships = useCurrentOrganizationMemberships();

    if (!enterpriseUid) {
        return undefined;
    }

    return currentMemberships.find((m) => m.enterpriseUid === enterpriseUid);
}

/**
 * Whether the current user is considered an owner of the document
 *
 * This boils down to one of two cases:
 *  - The user originally created the document (i.e. is listed as the user-owner of the document, even though it may be in an organization), or
 *  - The user is an owner-role member of the organization that owns the document
 *  - The user is an owner-role member of the enterprise that owns the document
 */
export function useIsDocumentOwner(
    documentOwnerUid: UserUid | undefined,
    documentOrganizationOwnerUid: OrganizationUid | undefined,
    documentEnterpriseOwner: Enterprise | undefined,
): boolean {
    const currentUser = useCurrentUser();
    const currentOrganizationMembershipForDocument =
        useCurrentOrganizationMembershipForOrganization(documentOrganizationOwnerUid);
    const currentEnterpriseMembershipForDocument = useCurrentOrganizationMembershipForOrganization(
        documentEnterpriseOwner?.defaultOrganizationUid,
    );
    return isDocumentOwner(
        currentUser,
        documentOwnerUid,
        currentOrganizationMembershipForDocument,
        currentEnterpriseMembershipForDocument,
    );
}

export function isDocumentOwner(
    currentUser: IUserData | undefined,
    documentOwnerUid: string | undefined,
    currentOrganizationMembershipForDocument: OrganizationMember | undefined,
    currentEnterpriseMembershipForDocument: OrganizationMember | undefined,
): boolean {
    return (
        !!currentUser &&
        !!documentOwnerUid &&
        (documentOwnerUid === currentUser?.uid ||
            currentOrganizationMembershipForDocument?.role === OrganizationRole.Values.owner ||
            currentEnterpriseMembershipForDocument?.role === EnterpriseRole.Values.owner)
    );
}

/**
 * Whether the current user is an owner-role member of the organization that owns the document, which is passed in
 */
export function useIsDocumentOrganizationOwner(documentOrganizationOwnerUid: OrganizationUid | undefined): boolean {
    const currentUser = useCurrentUser();
    const currentOrganizationMembershipForDocument =
        useCurrentOrganizationMembershipForOrganization(documentOrganizationOwnerUid);

    return !!currentUser && currentOrganizationMembershipForDocument?.role === "owner";
}

export function useOrganizationPrivateMetadata(
    organizationUid: OrganizationUid | undefined,
): OrganizationPrivateMetadata | undefined {
    const {organizationPrivateMetadataRepository} = useFluxServices();
    const [organizationPrivateMetadata, setOrganizationPrivateMetadata] = useState<
        OrganizationPrivateMetadata | undefined
    >(undefined);

    // If we aren't a member of the organization, we can't access the OPM
    const isCurrentUserMemberInOrganization = !!useCurrentOrganizationMembershipForOrganization(organizationUid);

    useEffect(() => {
        if (!organizationUid || !isCurrentUserMemberInOrganization) {
            setOrganizationPrivateMetadata(undefined);
            return;
        }

        void organizationPrivateMetadataRepository.getByUid(organizationUid).then((opm) => {
            setOrganizationPrivateMetadata(opm);
        });
    }, [organizationPrivateMetadataRepository, organizationUid, isCurrentUserMemberInOrganization]);

    return organizationPrivateMetadata;
}

/**
 * Returns the organization's copilot credit visibility, set in the private metadata configuration
 */
export function useCreditVisibilityForOrganization(organizationUid: OrganizationUid | undefined): boolean | undefined {
    const organizationPrivateMetadata = useOrganizationPrivateMetadata(organizationUid);
    return organizationPrivateMetadata?.configuration?.displayCopilotCredits ?? true;
}

export function useIsCurrentUserMemberOfOrganizationWithPaidPlan(): boolean {
    const currentMemberships = useCurrentOrganizationMemberships();

    return currentMemberships
        .map((m) => getPaymentPlanCategory(m.organization))
        .some((category) => isPaidPaymentPlanCategory(category));
}
