import {ANON_ROLE_UID} from "@buildwithflux/constants";
import {
    CameraMode,
    documentConfigUid,
    EditorModes,
    IDocumentConfigData,
    IDocumentConfigsMap,
    IDocumentConfigValue,
} from "@buildwithflux/core";
import {createNoAccess, DocumentPermissionsData, IDocumentData, PermissionType} from "@buildwithflux/models";

export default class Document {
    /**
     * Updates the document with new role information.
     *
     * This is the method used by the document reducer to modify the IDocumentData when a user is given a role on the
     * document via the setRole action (e.g. dispatched by the sharing dialog)
     *
     * @param document The document to update - it will be mutated by this function.
     * @param permissions Permissions to set.
     * @remarks If the permission type is set to "none", then the user will _also_ be removed from the list of users who have access to this document.
     * @todo Move to plain exported function in models
     *
     * @see anonymousAccessLevel
     * @see userPermissionTypeFromRoles
     */
    public static setUserPermissions(document: IDocumentData, permissions: DocumentPermissionsData): void {
        // Check to see if there's anything to do.
        const currentPerms = document.roles[permissions.user_uid];
        if (currentPerms === permissions) return;

        // Set the role on the document and update the user access list if necessary.
        document.roles[permissions.user_uid] = {
            ...permissions,
            updated_at: new Date().getTime(),
        };
        if (!document.user_access_list) document.user_access_list = [];
        // If the permission type is none, ensure that the user is not included in the access list.  Otherwise,
        // add them.
        if (permissions.permission_type === PermissionType.none) {
            document.user_access_list = document.user_access_list.filter(
                (allowedUid) => allowedUid !== permissions.user_uid,
            );
        } else {
            if (!document.user_access_list.includes(permissions.user_uid))
                document.user_access_list.push(permissions.user_uid);
        }

        document.has_anon_access = document.user_access_list.includes(ANON_ROLE_UID);
    }

    /**
     * Completely remove a user from the set of permissions on the document
     *
     * @param doc The document to update - it will be mutated by this function.
     * @param userUid The user to be removed.
     *
     * @remarks
     * - The anonymous user cannot be removed in this way.  We only guarantee that calling this function with
     * the anonymous user as the uid argument has the same effect as setting the permission type for the anonymous
     * user to none.
     * - If the user is not included in the document permissions, this function is a no-op.
     * - Note that this is distinct from setting the user's permissions to "none".
     * @todo Move to plain exported function in models
     */
    public static removeUserPermissions(document: IDocumentData, userUid: string): void {
        if (userUid === ANON_ROLE_UID) {
            return Document.setUserPermissions(document, createNoAccess(userUid));
        }
        if (document.user_access_list && Document.userInAccessList(document, userUid)) {
            document.user_access_list = document.user_access_list.filter((uid) => uid !== userUid);
        }
        const existingPermissionsData = document.roles[userUid];
        if (existingPermissionsData) delete document.roles[userUid];
    }

    /**
     * Return the type of permission that a given user has on a document.
     *
     * @remarks There is a hierarchy of permission types.  In order,
     *
     * 1) The user has a permission type specified in the roles.
     * 2) If not, the user has the same level of permission as the anonymous user.
     * 3) If there is no data for the anonymous user, we fall back on no permission.
     *
     * QUESTION: how exactly is this related to the function userPermissionType in the models package?
     *
     * @deprecated Use userPermissionType standalone function instead.
     */
    public static userPermissionType(document: Readonly<IDocumentData>, userUid: Readonly<string>): PermissionType {
        if (userUid === document.owner_uid) return PermissionType.edit;
        const perms = document.roles[userUid];
        if (perms) return perms.permission_type;
        else {
            const anonPerms = document.roles[ANON_ROLE_UID];
            if (anonPerms) return anonPerms.permission_type;
        }
        return PermissionType.none;
    }

    /**
     * Does the user have independent read access via the access list
     *
     * This doesn't really determine visibility, because of other factors such as organizations
     */
    public static userInAccessList(document: Readonly<IDocumentData>, userUid: Readonly<string>): boolean {
        // Should never be the case, because even empty documents have an owner.
        if (document.user_access_list === undefined) return false;
        return document.user_access_list.includes(userUid);
    }

    /**
     * Set a document to private i.e. only the owner is allowed to see the document.
     * @param document The document to be made private.  It will be mutated by this function.
     * @remarks After this operation, all users who currently have access to the document will have their permission type set
     * to "none", but will not be removed from the set of permissions data.
     */
    public static setPermissionsToPrivate(document: IDocumentData): void {
        const currentlySetUids = Object.values(document.roles)
            .map((permissionData) => permissionData.user_uid)
            .filter((uid) => uid !== document.owner_uid);

        currentlySetUids.forEach((uid) => Document.setUserPermissions(document, createNoAccess(uid)));
    }

    public static editorStateForUrlEditorQueryParamValue(
        queryParamValue: string,
    ): [EditorModes, CameraMode | undefined] {
        let editorMode: EditorModes = EditorModes.schematic;
        let cameraMode: CameraMode | undefined = undefined;
        switch (queryParamValue) {
            case "code": {
                editorMode = EditorModes.code;
                break;
            }
            case "pcb_2d": {
                editorMode = EditorModes.pcb;
                cameraMode = CameraMode.two_d;
                break;
            }
            case "pcb": {
                editorMode = EditorModes.pcb;
                cameraMode = CameraMode.two_d;
                break;
            }
            case "pcb_3d": {
                editorMode = EditorModes.pcb;
                cameraMode = CameraMode.three_d;
                break;
            }
        }
        return [editorMode, cameraMode];
    }

    public static getUserOrDefaultDocumentProperty(
        documentConfigs: IDocumentConfigsMap,
        documentOwnerUid: string,
        currentUserUid: string,
        configKey: string,
        configSection: string,
    ): Partial<IDocumentConfigData> | undefined {
        if (!documentConfigs) {
            return;
        }

        let configData =
            documentConfigs[documentConfigUid({key: configKey, section: configSection, user_uid: currentUserUid})];

        if (!configData) {
            configData =
                documentConfigs[
                    documentConfigUid({key: configKey, section: configSection, user_uid: documentOwnerUid})
                ];
        }

        if (!configData) {
            return;
        }

        return configData;
    }

    public static configValueIsControlPositionData(value: IDocumentConfigValue): value is ControlPositionData {
        return (
            Array.isArray(value) || typeof value === "string" || typeof value === "number" || typeof value === "boolean"
        );
    }

    public static configValueIsControlPositionDataMap(value: IDocumentConfigValue): value is ControlPositionDataMap {
        return (
            typeof value === "object" &&
            Object.values(value).every(Document.configValueIsControlPositionData) &&
            Object.keys(value).every((key) => typeof key === "string")
        );
    }
}
