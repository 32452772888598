import {ROUTE_BRANCH_POINT_TERMINAL_UID} from "@buildwithflux/constants";
import {devAssert} from "@buildwithflux/core";
import {createTUID, Orientation, orientationOfVector} from "@buildwithflux/models";
import {find, isEqual, minBy, uniq, uniqBy} from "lodash";
import {Vector2} from "three";

import {compareFloats, compareVec2, roundFloatError, roundVectorFloatError} from "../../../helpers/float";
import {LineSegment} from "../../../helpers/geometry";
import {FluxLogger} from "../../storage_engine/connectors/LogConnector";

import Vertex from "./Vertex";
import type Wiring from "./Wiring";

// For brevity, since we use these values a lot:
const {HORIZONTAL, VERTICAL} = Orientation;

/* Note: most of the private functions below are design specifically to only work
 * with perfectly-perpendicular, axis-aligned, float-rounded segments and vertices.
 * normalizeConnectedWires() explicitly ensures this before calling them.
 * In other words, don't export these for use elsewhere.
 */

function segmentOrientation(segment: LineSegment) {
    return orientationOfVector(segment[1].clone().sub(segment[0]));
}

function orientationInfo(segment: LineSegment) {
    const orientation = segmentOrientation(segment);
    const alignedAxis = (orientation === HORIZONTAL ? "x" : "y") as "x" | "y";
    const perpendicularAxis = (orientation === HORIZONTAL ? "y" : "x") as "x" | "y";
    return {orientation, alignedAxis, perpendicularAxis};
}

function segmentLength(segment: LineSegment) {
    return segment[1].distanceTo(segment[0]);
}

// TODO can we replace this with the more general geometry:segmentsOverlap()? With care if so,
// because this does some specific things that are important for normalization.
function segmentsOverlap(s1: LineSegment, s2: LineSegment) {
    // Note: this function makes a bunch of assumptions. Eg segments are axis-aligned,
    // s1 is non-zero-length, and endpoints have been float-rounded. Hence this func is private.
    const {alignedAxis, perpendicularAxis} = orientationInfo([s1[0], s1[1]]);
    const sameLine =
        s1[0][perpendicularAxis] === s2[0][perpendicularAxis] &&
        // Ensure s2 is either same orientation or 0-length:
        s2[0][perpendicularAxis] === s2[1][perpendicularAxis];
    if (!sameLine) return false;
    const s1Ends: [number, number] = [s1[0][alignedAxis], s1[1][alignedAxis]];
    const s2Ends: [number, number] = [s2[0][alignedAxis], s2[1][alignedAxis]];
    // Ensure endpoints are in increasing order for correct inside/outside comparisons below.
    s1Ends.sort((a, b) => a - b);
    s2Ends.sort((a, b) => a - b);
    // >= <= (not > <) is important here so as to include segments that are exactly
    // touching because they're connected to the same vertex.
    // Otherwise we'd need another step to check .connectedVertices of segments.
    return s1Ends[0] <= s2Ends[1] && s2Ends[0] <= s1Ends[1];
}

function isBetween(n: number, range: [number, number]) {
    range.sort((a, b) => a - b);
    // >= <= is important for exact endpoint matching.
    return n >= range[0] && n <= range[1];
}

function orthogonalIntersect(s1: LineSegment, s2: LineSegment) {
    // Simplified segment intersection that only works if the lines are axis-aligned and perpendicular
    const orientations = [segmentOrientation(s1), segmentOrientation(s2)];
    if (orientations[0] === orientations[1]) return undefined;
    if (orientations[0] === VERTICAL) {
        [s1, s2] = [s2, s1];
    }
    if (isBetween(s2[0].x, [s1[0].x, s1[1].x]) && isBetween(s1[0].y, [s2[0].y, s2[1].y])) {
        return new Vector2(s2[0].x, s1[0].y);
    }
    return undefined;
}

function getOverlappingSegments(
    segment: LineSegment,
    allSegments: LineSegment[],
    _processed = new Set<string>(),
): LineSegment[] {
    _processed.add(makeSegmentId(segment));
    const overlapping = allSegments.filter((s) => segmentsOverlap(segment, s));
    const allOverlapping = [segment];
    for (const other of overlapping) {
        if (_processed.has(makeSegmentId(other))) continue;
        allOverlapping.push(...getOverlappingSegments(other, allSegments, _processed));
    }
    return allOverlapping;
}

function makeSegmentId(segment: LineSegment) {
    // Use the positions for the segment id, because identically-positioned segments make no difference to the logic.
    return [...segment[0].toArray(), ...segment[1].toArray()].sort().join(":");
}

/**
 * Given a vertex, normalize all the wires in the same net (but not across portals).
 *
 * NOTE: intended to be called via Wiring.normalizeConnectedWires(). Only exists here to
 * keep it in its own file because it's a large function.
 */
export function normalizeConnectedWires(wiringManager: Wiring, vertex: Vertex) {
    // TODO Maybe combine multiple vertices that point to the same terminal for any terminals of this net?
    // -> with care, because I think that'd require traversing all vertices, and break our perf-safety-boundary of
    // just traversing the net vertices.

    // Turn vertices into segments:
    const existingVertices = wiringManager.getAllNetVerticesAcrossPortals(vertex);
    const segmentMap: {[segmentId: string]: LineSegment} = {};
    for (const vertex of existingVertices) {
        // For each connected vertex, create a segment between it and this vertex.
        // If there are no connections, convert it to a zero-length segment, so as
        // to preserve isolated vertices. We do this because in certain rare-ish
        // cases, singletons exist as an intermediate step in rewiring (for eg when
        // autoplacement deletes a wire's internal vertices to recreate it from
        // endpoint vertices)
        const connectedVertices = vertex.connectedVertices.length
            ? vertex.connectedVertices.map((c) => c.vertex)
            : [vertex];
        for (const other of connectedVertices) {
            const segment: LineSegment = [
                // Round off float error when creating segments, to avoid subtle
                // float inequality issues later in the code. Should-be-equal-but-not
                // numbers easily get assigned to vertex.position by various UI code,
                // and part of the job of normalization is to clean those up.
                roundVectorFloatError(vertex.position),
                roundVectorFloatError(other.position),
            ];
            const segmentId = makeSegmentId(segment);
            if (segmentId in segmentMap) continue;
            segmentMap[segmentId] = segment;
        }
    }

    // Filter out zero-length segments and add them back in later, because they make
    // the merge logic more complicated, and we can just merge them in after
    const segments = Object.values(segmentMap).filter((s) => segmentLength(s));
    const zeroSegments = Object.values(segmentMap).filter((s) => !segmentLength(s));
    const terminalVertices = existingVertices.filter(
        (v) => v.terminalData && v.terminalData.terminal_uid !== ROUTE_BRANCH_POINT_TERMINAL_UID,
    );

    // Merge multiple overlapping and connected segments into single segments:
    const processedSegments = new Set<string>();
    const mergedSegments: LineSegment[] = [];
    for (const segment of segments) {
        if (processedSegments.has(makeSegmentId(segment))) continue;
        const overlapping = getOverlappingSegments(segment, segments);
        overlapping.forEach((s) => processedSegments.add(makeSegmentId(s)));
        const {orientation, alignedAxis, perpendicularAxis} = orientationInfo([overlapping[0]![0], overlapping[0]![1]]);
        const pointsOnSegment = overlapping.flatMap((s) => [s[0][alignedAxis], s[1][alignedAxis]]);
        const ends: [[number, number], [number, number]] = [
            [Math.min(...pointsOnSegment), segment[0][perpendicularAxis]],
            [Math.max(...pointsOnSegment), segment[0][perpendicularAxis]],
        ];
        if (orientation === VERTICAL) {
            ends.forEach((end) => end.reverse());
        }
        mergedSegments.push([new Vector2(...ends[0]), new Vector2(...ends[1])]);
    }

    // Add back in zero-length segments that aren't already spanned by nonzero segments (needed for
    // co-located terminals and for preserving isolated vertices, per earlier comment):
    mergedSegments.push(...zeroSegments.filter((z) => !mergedSegments.some((s2) => segmentsOverlap(s2, z))));

    // Next, split merged segments at the points that are actually joined to other segments,
    // and add vertices at the correct points (intersections, terminals, endpoints).
    const normalizedVertices: {[id: string]: Vertex} = {};
    function vertexAt(point: Vector2) {
        // Vertex positions aren't (necessarily) pre-rounded below, unlike the segments
        // above, so explicitly round to avoid bad float comparisons.
        // TODO PERF vertexAt() and these n^2 lookups are the major perf bottleneck of normalization,
        // which is starting to get slow on big nets with a lot of portals (eg ground!).
        // Solve it by indexing them by (float-rounded) position.
        let vertex = find(normalizedVertices, (v) => compareVec2(v.position, point));
        if (!vertex) {
            vertex = existingVertices.find((v) => compareVec2(v.position, point));
            if (vertex) {
                vertex.connectedVertices = [];
                vertex.routeIds = new Set();
            } else {
                vertex = new Vertex(point.x, point.y);
            }
            normalizedVertices[vertex.id] = vertex;
        }
        return vertex;
    }
    for (const segment of mergedSegments) {
        // TODO some ~O(N^2) loops in here we may need to consider factoring out. It's not terrible
        // though because N is only the size of a single net, i.e. small compared to the total number
        // of wires in a project. Also we only run this code on individual actions, not eg during render.
        const joinPoints = mergedSegments.map((s) => orthogonalIntersect(segment, s)).filter((p): p is Vector2 => !!p);
        const {orientation, alignedAxis, perpendicularAxis} = orientationInfo(segment);
        const terminalPositions = terminalVertices
            .map((t) => t.position)
            .filter(
                (p) =>
                    compareFloats(p[perpendicularAxis], segment[0][perpendicularAxis]) &&
                    isBetween(p[alignedAxis], [segment[0][alignedAxis], segment[1][alignedAxis]]),
            );
        joinPoints.push(...terminalPositions);
        joinPoints.push(...segment);
        joinPoints.sort((a, b) => a[alignedAxis] - b[alignedAxis]);
        let prev: Vertex | undefined = undefined;
        for (const point of uniqBy(joinPoints, (p) => roundFloatError(p[alignedAxis]))) {
            const vertex = vertexAt(point);
            if (prev) {
                prev.connectedVertices.push({vertex, type: orientation});
                vertex.connectedVertices.push({vertex: prev, type: orientation});
            }
            prev = vertex;
        }
    }

    // Ensure all terminals connected to the previous wires are connected to the new set of wires:
    if (mergedSegments.length || terminalVertices.length > 1) {
        const missingTerminalVertices = terminalVertices.filter(
            (existing) => !find(normalizedVertices, (v) => isEqual(existing.terminalData, v.terminalData)),
        );
        for (const missing of missingTerminalVertices) {
            let colocatedVertex = find(normalizedVertices, (v) => compareVec2(v.position, missing.position));
            if (!colocatedVertex) {
                // If we get here, normalization failed on at least one terminal, possibly due to
                // diagonal wires due to bugs elsewhere. Handle gracefully, because arguably this
                // layer of code shouldn't have opinions on diagonal wires, and also because it's
                // the best way in the current structure to avoid further corruption and user pain.
                devAssert(
                    false,
                    `Wire normalization didn't create a vertex at terminal ${createTUID(missing.terminalData!)}`,
                    FluxLogger,
                );
                const connection = missing.connectedVertices[0];
                missing.connectedVertices = [];
                if (connection) {
                    const connected =
                        normalizedVertices[connection.vertex.id] ??
                        minBy(Object.values(normalizedVertices), (v) =>
                            v.position.distanceTo(connection.vertex.position),
                        );
                    if (!connected) {
                        throw new Error(
                            "Empty normalizedVertices list. Should be impossible since mergedSegments.length > 0",
                        );
                    }
                    missing.connectedVertices.push({vertex: connected, type: connection.type});
                    connected.connectedVertices.push({vertex: missing, type: connection.type});
                }
                normalizedVertices[missing.id] = missing;
                continue;
            }
            if (colocatedVertex.terminalData) {
                // If there's already a terminal attached to that vertex, add original vertex at the
                // same position (each terminal needs its own vertex). This will handle any
                // zero-length segments in the net that connect co-located terminals.
                missing.connectedVertices = [];
                colocatedVertex.connectedVertices.push({vertex: missing, type: HORIZONTAL});
                missing.connectedVertices.push({vertex: colocatedVertex, type: HORIZONTAL});
                normalizedVertices[missing.id] = missing;
                colocatedVertex = missing;
            }
            colocatedVertex.terminalData = missing.terminalData;
        }
    }

    // Persist to the class's vertex map:
    const toDelete = existingVertices.filter((v) => !(v.id in normalizedVertices));
    toDelete.forEach((v) => wiringManager.vertices.delete(v.id));
    for (const vertex of Object.values(normalizedVertices)) {
        wiringManager.vertices.set(vertex.id, vertex);
    }
    return uniq([...existingVertices, ...Object.values(normalizedVertices), ...toDelete]);
}
