import {
    aggregateReviewStatus,
    Review,
    ReviewAssignedStatus,
    ReviewCheckAssignedStatus,
    ReviewCategory,
    ReviewCheckUid,
    ReviewStatus,
    ReviewUid,
    ReviewCheck,
    ReviewComment,
} from "@buildwithflux/models";
import {create, StateCreator, StoreApi, UseBoundStore} from "zustand";

import {DRCReviewCategory} from "../../components/pages/document/components/editor/components/right_drawer/review/helpers/convertDRCResultToReview";

export interface ReviewState {
    categories: ReviewCategory[];
    selectedReview?: Review;
    selectedCheckUid?: ReviewCheckUid;
    commentsById?: {[key: string]: ReviewComment[]};
    selectAReviewCheck({reviewUid, uid}: Pick<ReviewCheck, "uid" | "reviewUid">): void;
    toggleAReviewCheck({reviewUid, uid}: Pick<ReviewCheck, "uid" | "reviewUid">): void;
    clearSelectedReviewCheck(): void;
    setCommentsById(reviewUid: string, reviewCheckUid: string, comments: ReviewComment[]): void;
    setCategories(categories: ReviewCategory[]): void;
    setReview(review: Review): void;
    updateReviewStatus(reviewUid: ReviewUid, status: ReviewStatus): void;
    updateReviewAssignedStatus(reviewUid: ReviewUid, assignedStatus: ReviewAssignedStatus | undefined): void;
    updateReviewCheckAssignedStatus(
        reviewUid: ReviewUid,
        reviewCheckUid: ReviewCheckUid,
        assignedStatus: ReviewCheckAssignedStatus | undefined,
    ): void;
}

export type UseReviewStore = UseBoundStore<StoreApi<ReviewState>>;

function updateReviewStatus(categories: ReviewCategory[], uid: ReviewUid, status: ReviewStatus) {
    return categories.map((category) => ({
        ...category,
        reviews: category.reviews.map((item) => {
            if (item.uid !== uid) return item;
            return {
                ...item,
                status,
                checks: Object.keys(item.checks).reduce((acc, k) => ({...acc, [k]: {...item.checks[k], status}}), {}),
            };
        }),
    }));
}

function updateReviewAssignedStatus(
    categories: ReviewCategory[],
    uid: ReviewUid,
    assignedStatus: ReviewAssignedStatus | undefined,
): ReviewCategory[] {
    return categories.map((category) => ({
        ...category,
        reviews: category.reviews.map((item) => {
            if (item.uid !== uid) return item;
            return {
                ...item,
                assignedStatus,
            };
        }),
    }));
}

function updateReviewCheckAssignedStatus(
    categories: ReviewCategory[],
    reviewUid: ReviewUid,
    reviewCheckUid: ReviewCheckUid,
    assignedStatus: ReviewCheckAssignedStatus | undefined,
): ReviewCategory[] {
    return categories.map((category) => ({
        ...category,
        reviews: category.reviews.map((item: Review): Review => {
            if (item.uid !== reviewUid) return item;
            return {
                ...item,
                checks: {
                    ...item.checks,
                    [reviewCheckUid]: {
                        ...(item.checks[reviewCheckUid] as ReviewCheck),
                        assignedStatus,
                    },
                },
            };
        }),
    }));
}

function setReview(review: Review, categories: ReviewCategory[]): ReviewCategory[] {
    return categories.map((category) => ({
        ...category,
        reviews: category.reviews.map((item) => {
            if (item.uid !== review.uid) return item;
            return review;
        }),
    }));
}

export function calculateOverallStatus(categories: Array<ReviewCategory | DRCReviewCategory>) {
    const status = categories.map((category): ReviewStatus => {
        const reviewStatusList = category.reviews
            .map((review) => (review.assignedStatus ? undefined : review.status))
            .filter(Boolean) as ReviewStatus[];
        return reviewStatusList.length > 0 ? aggregateReviewStatus(reviewStatusList) : ReviewStatus.OPEN;
    });
    return status.length > 0 ? aggregateReviewStatus(status) : ReviewStatus.OPEN;
}

export function calculateOverallAssignedStatus(categories: Array<ReviewCategory | DRCReviewCategory>) {
    const isMuted = categories.length
        ? categories.every((category): boolean =>
              category.reviews.length
                  ? category.reviews.every((review) => review.assignedStatus === ReviewAssignedStatus.MUTED)
                  : false,
          )
        : false;
    return isMuted ? ReviewAssignedStatus.MUTED : undefined;
}

export function createReviewStore() {
    const selectionControllerStoreConfig: StateCreator<ReviewState> = (set) => ({
        categories: [],
        setCommentsById(reviewUid, reviewCheckUid, comments) {
            set(
                (state): ReviewState => ({
                    ...state,
                    commentsById: {
                        ...state.commentsById,
                        [`${reviewUid}:${reviewCheckUid}`]: comments,
                    },
                }),
            );
        },

        selectAReviewCheck({reviewUid, uid}: {reviewUid: ReviewUid; uid: ReviewCheckUid}) {
            set(
                (state): ReviewState => ({
                    ...state,
                    selectedReview: state.categories.flatMap((category) =>
                        category.reviews.filter((review) => review.uid === reviewUid),
                    )[0],
                    selectedCheckUid: uid,
                }),
            );
        },

        toggleAReviewCheck({reviewUid, uid}: {reviewUid: ReviewUid; uid: ReviewCheckUid}) {
            set((state): ReviewState => {
                const isCurrentlySelected = state.selectedCheckUid === uid && reviewUid === state.selectedReview?.uid;
                return {
                    ...state,
                    selectedReview: isCurrentlySelected
                        ? undefined
                        : state.categories.flatMap((category) =>
                              category.reviews.filter((review) => review.uid === reviewUid),
                          )[0],
                    selectedCheckUid: isCurrentlySelected ? undefined : uid,
                };
            });
        },

        clearSelectedReviewCheck() {
            set(
                (state): ReviewState => ({
                    ...state,
                    selectedReview: undefined,
                    selectedCheckUid: undefined,
                }),
            );
        },

        setCategories(categories: ReviewCategory[]) {
            set(
                (state): ReviewState => ({
                    ...state,
                    categories,
                }),
            );
        },

        setReview(review: Review) {
            set((state): ReviewState => {
                const categories = setReview(review, state.categories);
                return {
                    ...state,
                    categories,
                };
            });
        },

        updateReviewStatus(reviewUid: ReviewUid, status: ReviewStatus) {
            set((state): ReviewState => {
                const categories = updateReviewStatus(state.categories, reviewUid, status);
                return {
                    ...state,
                    categories,
                };
            });
        },

        updateReviewAssignedStatus(reviewUid: ReviewUid, assignedStatus: ReviewAssignedStatus | undefined) {
            set((state): ReviewState => {
                const categories = updateReviewAssignedStatus(state.categories, reviewUid, assignedStatus);
                return {
                    ...state,
                    categories,
                };
            });
        },
        updateReviewCheckAssignedStatus(
            reviewUid: ReviewUid,
            reviewCheckUid: ReviewCheckUid,
            assignedStatus: ReviewCheckAssignedStatus | undefined,
        ) {
            set((state): ReviewState => {
                const categories = updateReviewCheckAssignedStatus(
                    state.categories,
                    reviewUid,
                    reviewCheckUid,
                    assignedStatus,
                );
                return {
                    ...state,
                    categories,
                };
            });
        },
    });

    return create<ReviewState>()(selectionControllerStoreConfig);
}
