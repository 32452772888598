import {Vector2} from "three";

import R from "../resources/Namespace";

import {roundFloat} from "./float";
import {IVector2} from "@buildwithflux/models";

export function roundToDragPrecision(value: number) {
    const draggingPrecision = R.behaviors.dragging.gridPrecision;
    return roundFloat(value, draggingPrecision);
}

export function roundVectorToDragPrecision(vector: Vector2) {
    return new Vector2(roundToDragPrecision(vector.x), roundToDragPrecision(vector.y));
}

export function roundVec2ToDragPrecision(vector: IVector2) {
    return {x: roundToDragPrecision(vector.x), y: roundToDragPrecision(vector.y)};
}
