import {combineEpics, createEpicMiddleware} from "redux-observable";

import {
    makeGenerateActionRecordsEpic,
    makePatchHandlersEpic,
    saveDocumentConfigsEpic,
    saveDocumentEpic,
} from "../epicCreators/document";
import documentPatchManager from "../reducers/document/DocumentPatchManager";
import {publishPartResultNotifier, updatePartResultNotifier} from "../reducers/part/epics";

export const rootEpic = combineEpics(
    // Part related epics
    publishPartResultNotifier,
    updatePartResultNotifier,
    // Generate action records epic
    saveDocumentEpic,
    saveDocumentConfigsEpic,
    makePatchHandlersEpic(documentPatchManager),
    makeGenerateActionRecordsEpic(),
);

// TODO: inject getActiveServicesContainer() into epic middleware
// See https://redux-observable.js.org/docs/recipes/InjectingDependenciesIntoEpics.html
export const epicMiddleware = createEpicMiddleware();
