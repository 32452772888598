import {
    trackingEventCopilot,
    trackingEventCopilotCredits,
    trackingEventNewProjectDialog,
    trackingEventOrganizations,
    trackingEventPartUpdate,
} from "@buildwithflux/core";

// READ THIS FIRST BEFORE ADDING THINGS HERE!!!

// We are capped by how many events we can have in Amplitude...so we want to
// keep this list short!
// Additonally its easier to browse these events in analytics tools
// if their are organized by surface and NOT by action

// BAD Patterns
// exportFlx: "export_flx",
// exportKicad: "export_kicad",
// exportKicadPcb: "export_kicad_pcb",
// exportPng: "export_png",
// exportStl: "export_stl",
// exportBinaryStl: "export_binary_stl",

// Better Patterns
// export: "export",
// and then user a field called "action" on the event to differentiate between "kicad", "png", "stl", etc

export const TrackingEvents = {
    // Bad patterns we need to stop and migrate
    exportFlx: "export_flx",
    exportKicad: "export_kicad",
    exportKicadPcb: "export_kicad_pcb",
    exportPng: "export_png",
    exportStl: "export_stl",
    exportBinaryStl: "export_binary_stl",
    exportCollada: "export_collada",
    exportBom: "export_bom",
    exportPickAndPlace: "export_pick_and_place",
    exportGerber: "export_gerber",
    exportGerberNative: "export_gerber_native",
    exportNetlist: "export_netlist",
    exportJEP30: "export_JEP30",

    createDocument: "create_document",
    createBlankDocument: "create_blank_document",
    createDocumentFromTemplate: "create_document_from_template",

    cloneDocument: "clone_document",
    forkDocument: "fork_document",

    loadDocument: "load_document",
    loadedDocument: "loaded_document",
    loadedEditor: "loaded_editor",
    loadUserProfile: "load_user_profile",

    // these are just so bad...should have been "performance" and then a action field
    perfAddElements: "addElements",
    perfAddLocalRule: "addLocalRule",
    perfAddPropertiesDocument: "addProperties__document",
    perfAddPropertiesSubject: "addProperties__subject",
    perfAddSubjectCommentThread: "addSubject__commentThread",
    perfAddSubjectUserPresence: "addSubject__userPresence",
    perfDeletePcbLayoutNodes: "deletePcbLayoutNodes",
    perfDeletePcbLayoutRuleSets: "deletePcbLayoutRuleSets",
    perfDeleteSchematicSubjects: "deleteSchematicSubjects",
    perfNetAddRouteSegment: "Net__addRouteSegment",
    perfOnDragEndPcb: "onDragEnd__pcb",
    perfPcbLayoutRuleListChangeGlobal: "PcbLayoutRuleList__change__global",
    perfPcbLayoutRuleListChangeLocal: "PcbLayoutRuleList__change__local",
    perfPerformPlaceVia: "performPlaceVia",
    perfPerformSwitchRoutingLayer: "performSwitchRoutingLayer",
    perfPerformZoomToFitSchematic: "performZoomToFit__schematic",
    perfPerformZoomToFitSchematicCode: "performZoomToFit__code",
    perfPerformZoomToFitSchematicPcb: "performZoomToFit__pcb",
    perfPlaceWire: "placeWire",
    perfRedo: "redo__perf",
    perfRemovePropertiesDocument: "removeProperties__document",
    perfRemovePropertiesSubject: "removeProperties__subject",
    perfRotatePcbNode: "rotatePcbNode",
    perfRotateSubjects: "rotateSubjects",
    perfSelectSubject: "selectSubject",
    perfSetAirwireVisibility: "setAirwireVisibility",
    perfSetCopperFillVisibility: "setCopperFillVisibility",
    perfSetEditorModeSchematic: "setEditorMode__schematic",
    perfSetEditorModeCode: "setEditorMode__code",
    perfSetEditorModePcb: "setEditorMode__pcb",
    perfSetFlippedCamera: "setFlippedCamera",
    perfSetLabelsVisibility: "setLabelsVisibility",
    perfSetLayerVisibility: "setLayerVisibility",
    perfSetMarginVisibility: "setMarginVisibility",
    perfSetPcbLayoutRuleSetsMove: "setPcbLayoutRuleSets__move",
    perfSetPcbShowGrid: "setPcbShowGrid",
    perfSetSchematicShowGrid: "setSchematicShowGrid",
    perfSetToggleObjectSpecificPcbRule: "setToggleObjectSpecificPcbRule",
    perfSetTogglePcbRule: "setTogglePcbRule",
    perfSetTraceWidth: "setTraceWidth",
    perfUndo: "undo__perf",
    perfUpdateCameraMode2d: "updateCameraMode__two_d",
    perfUpdateCameraMode3d: "updateCameraMode__three_d",
    perfUpdatePropertiesDocument: "updateProperties__document",
    perfUpdatePropertiesSubject: "updateProperties__subject",

    // a better patttern would have been to have a version field on the event
    signIn: "sign_in",
    signInV2: "sign_in_v2",
    signOut: "sign_out",
    signUp: "sign_up",
    signUpV2: "sign_up_v2",

    copyPaste: "copy_paste",
    copyPasteSpecial: "copy_paste_special",

    alignmentPcb: "alignment_pcb",
    alignmentSchematics: "alignment_schematics",

    deleteDocument: "delete_document",
    deletePartAndPartVersions: "delete_part_and_part_versions",
    designRuleCheck: "design_rule_check",

    deleteObjects: "delete_objects",

    fetchPartAvail: "fetch_part_availability",
    filterDocumentContents: "filter_document_contents",
    flipLayer: "flip_layer",
    flipSubjects: "flip_subjects",

    importKicadPart: "import_kicad_part",
    inviteAccepted: "invite_accepted",

    manufactureButtonClick: "manufacture_button_click",
    measureTool: "measure_tool",
    mobileVisitor: "mobile_visitor",
    navControlSettings: "navcontrols_settings",

    playNewRemoteUserSound: "play_new_remote_user_sound",
    previewGerber: "preview_gerber",

    redo: "redo",
    replaceElement: "replace_element",
    requestDocument: "request_document",
    resetPassword: "reset_password",
    /**
     * @deprecated see TrackingEventActions.changeHistory.restore
     */
    revertDocumentVersion: "revertDocumentVersion",
    rotateSubjects: "rotate_subjects",
    selectEntireWire: "select_entire_wire",
    setPart: "set_part",
    setPartVersion: "set_part_version",
    setUsername: "set_username",
    shadowbanParts: "shadowban_parts", // internal-only

    signupCleanupDeleteUser: "signup_cleanup_delete_user",

    spaceEvenly: "space_evenly",
    starDocument: "star_document",
    switchLayerDuringRouting: "switch_layer_during_routing",

    unarchivePart: "unarchive_part",
    unstarDocument: "unstar_document",
    undo: "undo",
    updateDocument: "update_document",
    updatePart: "update_part",

    visitorContinueToDesktop: "visitor_continue_to_desktop",
    setLayoutRule: "setLayoutRule",

    addPartToDocument: "add_part_to_document",
    addPcbNode: "addPcbNode",
    addViaDuringRouting: "add_via_during_routing",

    appLoad: "AppLoad",
    archiveDocument: "archive_document",
    archivePart: "archive_part",
    autoSpaceSubjects: "auto_space_subjects",

    // Good patterns we should use

    // by object type
    assets: "assets",
    comments: "comments",
    organizations: trackingEventOrganizations,
    users: "users",
    accounts: "accounts",
    projects: "projects",
    components: "components",
    parts: "parts",

    // general
    pageView: "PageView",

    // by surface
    onboardingUx: "onboardingUX",
    partPublishing: "part_publishing",
    partSearch: "part_search",
    permissionDialog: "permission_dialog",
    shortcutViewer: "shortcut_viewer",
    showPaywall: "show_paywall",
    siteSearch: "site_search",
    fileStorage: "file_storage",
    captureLeadEmail: "capture_lead_email",
    profileDocumentSearch: "profile_document_search",
    projectTemplate: "ProjectTemplate",
    toolbarNodeEdit: "toolbar_node_edit",
    componentLibraryRefinement: "component_library_refinement",
    copilot: trackingEventCopilot,
    copilotCredits: trackingEventCopilotCredits,
    editor: "editor",
    newProjectDialog: trackingEventNewProjectDialog,
    markdown: "markdown",
    accountMenu: "account_menu",
    changeHistory: "change_history",
    pcbEditor: "pcb_editor",

    // Part updates
    partUpdate: trackingEventPartUpdate,

    // Smart via
    smartVia: "smart_via",
    stackupEditor: "stackup_editor",
} as const;

export type TrackingEvent = (typeof TrackingEvents)[keyof typeof TrackingEvents];

/**
 * @deprecated This object was created to help prevent typos / facilitate Amplitude
 * browsing.
 * QUESTION: what to replace it with?
 *
 * TODO: refactor to separate files for each. Using typing like that of `trackPartUpdateEvent`
 * on AnalyticsStorage.
 **/
export const TrackingEventActions = {
    createDocument: {
        blank: "blank",
        clone: "clone",
        fork: "fork",
    },
    copilot: {
        import: {
            importSingle: "import_single",
            importAll: "import_all",
            closeDialog: "close_datasheet_import_dialog",
            openDialog: "open_datasheet_import_dialog",
            jobDataReceived: "job_data_received",
            uploadPdfClicked: "upload_pdf_clicked",
            pdfUploaded: "pdf_uploaded",
            cancelImportJobClicked: "cancel_import_job_clicked",
            importComponentInitialTemplateMatchComplete: "import_component_initial_template_match_complete",
        },
    },
    changeHistory: {
        revertOrForward: "revertOrForward",
        restore: "restore",
        cancel: "cancel",
    },
} as const;

/**
 * All the "surface" values for newProjectDialog->open, places from which the
 * new project dialog can be opened.
 */
export const NewProjectDialogOrigin = {
    hotkey: "hotkey",
    menu: "menu",
    profile: "profile",
} as const;
