import {AccountUid, profileSettingsRoute, toAbsoluteUrl} from "@buildwithflux/core";
import {CreateStripeLinkResponseData, FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {areWeInStorybook, areWeTestingWithJest, isDevEnv, Logger, silentLogger, wait} from "@buildwithflux/shared";
import type {FirebaseError} from "@firebase/util";
import {HttpsCallableResult} from "firebase/functions";
import {produce} from "immer";
import omit from "lodash/omit";
import {create, StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";

import {useFluxServices} from "../../../injection/hooks";
import {useCurrentUser} from "../../auth/state/currentUser";

import {GetCheckoutUrlOptions, GetPortalUrlOptions, UrlType, useExistingPaymentInformation} from "./common";

const gracePeriod = 20 * 1000;
const checkoutUrlTtl = 1000 * 60 * 60 * 23 - gracePeriod; // Checkout links last for 24 hours
const portalUrlTtl = 1000 * 60 * 5 - gracePeriod; // Portal links last for 5 mins
const refetchBeforeExpiry = 1000 * 60; // Refetch non-stale 1 min before expiry

type PaymentUrlStoreApi = {
    getPortalUrl(options: GetPortalUrlOptions<UrlType>): string | undefined;
    getCheckoutUrl(options: GetCheckoutUrlOptions<UrlType>): string | undefined;
};

type UrlEntry = {
    fetch?: Promise<void>;
    url?: string;
    expiresAt?: number;
};

type PaymentUrlStoreState = PaymentUrlStoreApi & {
    checkoutUrls: {
        [key: string]: UrlEntry;
    };
    portalUrls: {
        [key: string]: UrlEntry;
    };
};

function storeKey(options: Readonly<GetCheckoutUrlOptions<UrlType> | GetPortalUrlOptions<UrlType>>): string {
    const optsForHash: Record<string, string> = {...omit(options, "organization")};

    if (options.ownerType === "organization" && options.organization) {
        optsForHash.organizationUid = options.organization.uid;
    }

    return Object.entries(optsForHash)
        .map(([k, v]) => `${k}:${JSON.stringify(v)}`)
        .sort()
        .join(",");
}

function needsRefetch(urlEntry: UrlEntry | undefined): boolean {
    // Fetch is already in progress
    if (urlEntry?.fetch) {
        return false;
    }

    return !urlEntry || !urlEntry.expiresAt || urlEntry.expiresAt - refetchBeforeExpiry < Date.now();
}

function isExpired(urlEntry: UrlEntry): boolean {
    return !urlEntry || !urlEntry.expiresAt || urlEntry.expiresAt < Date.now();
}

export type UsePaymentUrlStore = UseBoundStore<StoreApi<PaymentUrlStoreState>>;

export const createPaymentUrlStoreHook = (
    functionsAdapter: FunctionsAdapter,
    errorLogger: Logger,
    debugLogger: Logger = silentLogger,
) =>
    create<PaymentUrlStoreState>()(
        devtools(
            (set, get) => {
                async function fetchPortalUrl(
                    key: string,
                    options: GetPortalUrlOptions<UrlType>,
                    attempt = 0,
                ): Promise<void> {
                    let fetch: Promise<HttpsCallableResult<CreateStripeLinkResponseData>>;

                    if (options.ownerType === "organization") {
                        if (!options.organization) {
                            debugLogger.debug(
                                "Skipping fetch of payment portal URL for organization: no organization provided",
                                {key},
                            );
                            return;
                        }

                        debugLogger.debug("Started fetch of payment portal URL for organization", {key});
                        fetch = functionsAdapter.createStripePortalLink({
                            ...omit(options, "organization"),
                            organizationUid: options.organization.uid,
                        });
                    } else {
                        debugLogger.debug("Started fetch of payment portal URL for user", {key});
                        fetch = functionsAdapter.createStripePortalLink(options);
                    }

                    set(
                        produce((state) => {
                            if (!state.portalUrls[key]) {
                                state.portalUrls[key] = {fetch};
                            } else {
                                state.portalUrls[key].fetch = fetch;
                            }
                        }),
                    );

                    let url: string | undefined = undefined;

                    try {
                        url = (await fetch)?.data?.url;

                        if (!url) {
                            throw new Error(`Failed to fetch payment portal URL for ${key}`);
                        }
                    } catch (error) {
                        if ((error as FirebaseError)?.code === "functions/internal" && attempt < 3) {
                            /*
                             * This is some sort of CORS error, perhaps due to a lack of Access-Control-Allow-Credentials
                             * in the CORS response
                             *
                             * But as this is an onCall, Firebase is supposed to be handling the CORS response for us,
                             * and we don't really have control over it.
                             *
                             * All we can do is retry, which actually usually succeeds after some delay. This same handling
                             * has been applied elsewhere in the codebase, e.g. frontend/src/modules/payments/state/credit.ts:86
                             *
                             * TODO: upgrading to firebase-functions v2 in backend may help, or upgrading Firebase in general
                             * See https://linear.app/buildwithflux/issue/FLUX-4073/upgrade-to-firebase-functions-v2
                             */
                            await wait(2_000);
                            return await fetchPortalUrl(key, options, attempt + 1);
                        }

                        errorLogger.error(`Failed to fetch payment portal URL for ${key}`, error);

                        /*
                         * To re-throw here would trigger various error boundaries across the app; instead we let the
                         * specific state selecting hooks below, e.g. `usePaymentPortalUrl`, handle the error by returning
                         * `undefined` - this is already a state that the caller must deal with (e.g. if the user isn't
                         * logged in)
                         *
                         * Practical upshot: if Stripe is down, or we can't generate a customer, payment features won't
                         * show up (including a spinner on the NUX, etc.), but this is still more useful than an error
                         * boundary
                         */
                    }

                    if (url) {
                        debugLogger.debug("Updated payment portal URL", {key, url});
                        set(
                            produce((state) => {
                                state.portalUrls[key] = {
                                    fetch: undefined,
                                    url,
                                    expiresAt: Date.now() + portalUrlTtl,
                                };
                            }),
                        );
                    }
                }

                async function fetchCheckoutUrl(
                    key: string,
                    options: GetCheckoutUrlOptions<UrlType>,
                    attempt = 0,
                ): Promise<void> {
                    let fetch: Promise<HttpsCallableResult<CreateStripeLinkResponseData>>;

                    if (options.ownerType === "organization") {
                        const organization = options.organization;

                        if (!organization) {
                            debugLogger.debug("Skipping fetch of payment checkout URL - no organization", {key});
                            return;
                        } else {
                            debugLogger.debug("Started fetch of payment checkout URL for organization", {key});
                            fetch = functionsAdapter.createStripeCheckoutLink({
                                ownerType: "organization",
                                organizationUid: organization.uid,
                                successUrl: toAbsoluteUrl(
                                    profileSettingsRoute(organization.handle, {paymentSuccess: true}),
                                ),
                                cancelUrl: options.cancelUrl,
                                interval: options.interval,
                            });
                        }
                    } else {
                        debugLogger.debug("Started fetch of payment checkout URL for user", {key});
                        fetch = functionsAdapter.createStripeCheckoutLink({
                            ownerType: "user",
                            successUrl: options.successUrl,
                            cancelUrl: options.cancelUrl,
                            priceUid: options.priceUid,
                            interval: options.interval,
                            plan: options.plan,
                        });
                    }

                    set(
                        produce((state) => {
                            if (!state.checkoutUrls[key]) {
                                state.checkoutUrls[key] = {fetch};
                            } else {
                                state.checkoutUrls[key].fetch = fetch;
                            }
                        }),
                    );

                    let url: string | undefined = undefined;

                    try {
                        url = (await fetch)?.data?.url;
                        if (!url) {
                            throw new Error(`Failed to fetch payment checkout URL for ${key}`);
                        }
                    } catch (error) {
                        if ((error as FirebaseError)?.code === "functions/internal" && attempt < 3) {
                            /*
                             * This is some sort of CORS error, perhaps due to a lack of Access-Control-Allow-Credentials
                             * in the CORS response
                             *
                             * But as this is an onCall, Firebase is supposed to be handling the CORS response for us,
                             * and we don't really have control over it.
                             *
                             * All we can do is retry, which actually usually succeeds after some delay. This same handling
                             * has been applied elsewhere in the codebase, e.g. frontend/src/modules/payments/state/credit.ts:86
                             *
                             * TODO: upgrading to firebase-functions v2 in backend may help, or upgrading Firebase in general
                             * See https://linear.app/buildwithflux/issue/FLUX-4073/upgrade-to-firebase-functions-v2
                             */
                            await wait(2_000);
                            return await fetchCheckoutUrl(key, options, attempt + 1);
                        }

                        errorLogger.error("Failed to fetch payment checkout URL", {key, error});
                        throw error;
                    }

                    if (url) {
                        debugLogger.debug("Updated payment checkout URL", {key, url});
                        set(
                            produce((state) => {
                                state.checkoutUrls[key] = {
                                    fetch: undefined,
                                    url,
                                    expiresAt: Date.now() + checkoutUrlTtl,
                                };
                            }),
                        );
                    }
                }

                return {
                    // API
                    getPortalUrl(options: GetPortalUrlOptions<never>): string | undefined {
                        const key = storeKey(options);
                        const entry = get().portalUrls[key];
                        const url = entry && !isExpired(entry) ? entry?.url : undefined;

                        if (needsRefetch(entry)) {
                            void fetchPortalUrl(key, options);
                        }

                        return url;
                    },

                    getCheckoutUrl(options: GetCheckoutUrlOptions<never>): string | undefined {
                        const key = storeKey(options);
                        const entry = get().checkoutUrls[key];
                        const url = entry && !isExpired(entry) ? entry?.url : undefined;

                        if (needsRefetch(entry)) {
                            void fetchCheckoutUrl(key, options);
                        }

                        return url;
                    },

                    // State - update deeply with immer
                    checkoutUrls: {},
                    portalUrls: {},
                };
            },
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "PaymentUrlStore"},
        ),
    );

/**
 * Returns a Stripe payment portal URL, cached against the options you provide
 *
 * Portal links last for 5 minutes, and we refetch a minute before they expire, so repeated calls to this function
 * will usually return the same URL within the same 4-minute period
 */
export function usePaymentPortalUrl(
    options: GetPortalUrlOptions<UrlType> & {hasPaidActiveSubscription?: boolean},
): string | undefined {
    const useStore = useFluxServices().usePaymentUrlStore;
    const currentUser = useCurrentUser();
    const accountUid: AccountUid | undefined =
        options.ownerType === "organization" ? options.organization?.uid : currentUser?.uid;
    const {hasPermissionToManagePayments, hasPaidActiveSubscription} = useExistingPaymentInformation({
        hasPaidActiveSubscription: options.hasPaidActiveSubscription,
        accountUid,
    });

    return useStore((state) => {
        if (areWeInStorybook()) {
            return "#";
        }

        if (options.ownerType === "organization" && !options.organization) {
            return "#";
        }

        return hasPermissionToManagePayments && hasPaidActiveSubscription ? state.getPortalUrl(options) : undefined;
    });
}

/**
 * Returns a Stripe payment checkout URL, cached against the options you provide
 *
 * Checkout links last for 24 hours, and we refetch a minute before they expire, so repeated calls to this function
 * will usually return the same URL
 */
export function usePaymentCheckoutUrl(
    options: (GetCheckoutUrlOptions<UrlType> & {hasPaidActiveSubscription?: boolean}) | undefined,
): string | undefined {
    const useStore = useFluxServices().usePaymentUrlStore;
    const currentUser = useCurrentUser();
    const accountUid: AccountUid | undefined =
        options?.ownerType === "organization" ? options?.organization?.uid : currentUser?.uid;

    const {hasPermissionToManagePayments, hasPaidActiveSubscription} = useExistingPaymentInformation({
        accountUid,
        hasPaidActiveSubscription: options?.hasPaidActiveSubscription,
    });

    return useStore((state) => {
        if (!options) {
            return undefined;
        }

        if (areWeInStorybook()) {
            return "#";
        }

        if (options?.ownerType === "organization" && !options?.organization) {
            return "#";
        }

        return hasPermissionToManagePayments && !hasPaidActiveSubscription ? state.getCheckoutUrl(options) : undefined;
    });
}
