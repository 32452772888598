import {
    AbstractDocumentSnapshotStorageAdapter,
    DocumentSnapshotDescriptor,
    relativePathForDocumentSnapshotDescriptor,
    StoredDocumentSnapshotData,
} from "@buildwithflux/core";
import {CloudCdnStorageConfig} from "@buildwithflux/shared";
import {Auth, onIdTokenChanged, User} from "firebase/auth";

/**
 * Adapter for static document snapshot data based on the browser fetch API.  It can only read data,
 * not write it.
 */
export class BrowserDocumentSnapshotStorageAdapter implements AbstractDocumentSnapshotStorageAdapter {
    baseUrl: string;
    idTokenUnsubscriber: () => void;
    authenticated = false;
    authService: Auth;

    constructor(config: CloudCdnStorageConfig, authService: Auth) {
        this.baseUrl = config.baseUrl;
        this.authService = authService;
        // Set up a subscription so that any time the auth state changes to a
        // definite user, we ask for auth credentials.
        // TODO: Don't love this, but it'll work for now.
        // NOTE: Also see `BrowserStaticPartVersionStorageAdapter.ts`, where the
        // same onIdTokenChanged callback is registered, resulting in two calls per page load!
        this.idTokenUnsubscriber = onIdTokenChanged(authService, (user) => this.getCDNAuthToken(user));
    }

    /** @inheritDoc */
    public async get(
        descriptor: Readonly<DocumentSnapshotDescriptor>,
    ): Promise<StoredDocumentSnapshotData | undefined> {
        const path = relativePathForDocumentSnapshotDescriptor(descriptor);
        try {
            const response = await window.fetch(this.objectUrl(path), {credentials: "include"});
            if (response.ok) {
                return (await response.json()) as StoredDocumentSnapshotData;
            } else {
                if (response.status === 403) {
                    // Try geting a new CDN auth token, then try getting the snapshot again
                    // Prod logs have shown that this can happen when the token expires
                    const user = await this.authService.currentUser;
                    await this.getCDNAuthToken(user);
                    if (this.authenticated) {
                        const baseData = await window.fetch(this.objectUrl(path), {credentials: "include"});
                        if (baseData.ok) {
                            return (await baseData.json()) as StoredDocumentSnapshotData;
                        }
                    }
                }
                // eslint-disable-next-line no-console
                console.warn(
                    `Not ok response ${response.status} ${response.type} to request while auth ${
                        this.authenticated
                    } for snapshot at ${this.objectUrl(path)}`,
                );
                return undefined;
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(`Error fetching snapshot at ${this.objectUrl(path)}: ${err}`);
            return undefined;
        }
    }

    /**
     * This method will always fail on the client - do not use it!
     */
    public save(
        _documentSnapshot: Readonly<StoredDocumentSnapshotData>,
        _descriptor: Readonly<DocumentSnapshotDescriptor>,
    ): Promise<void> {
        // This throw is highly intentional.  DO NOT REMOVE IT.
        // TODO: Must be a better way to restrict access to this.
        throw new Error("Method not implemented for client.");
    }

    /**
     * @deprecated use get method instead
     * TODO: this should really be doing a HEAD request for performance
     */
    public async exists(descriptor: Readonly<DocumentSnapshotDescriptor>): Promise<boolean> {
        const remoteData = await this.get(descriptor);
        return remoteData != null;
    }

    /**
     * This will fire off the request to get the auth token.  On success, the server will set a cookie on the browser - its value
     * is never used other than to determine if the result was successful.
     */
    private async getCDNAuthToken(user: User | null): Promise<void> {
        const firebaseJwtToken = await user?.getIdToken();
        if (firebaseJwtToken) {
            const result = await window.fetch(`${this.baseUrl}/authenticate`, {
                credentials: "include",
                headers: {Authorization: `Bearer ${firebaseJwtToken}`},
            });
            // If we receive a 204, then we did it.
            this.authenticated = result.status === 204;
        } else {
            this.authenticated = false;
            return;
        }
    }

    /**
     * Construct the URL for a given path.
     */
    private objectUrl(path: string): string {
        return `${this.baseUrl}/${path}`;
    }
}
