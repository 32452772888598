import {FeatureFlagValues, IUserPrivateMetadata, LoginMethod, makeUserData} from "@buildwithflux/core";
import {ClientFirestoreAdapter, FirebaseUser, firstFromSnapshot} from "@buildwithflux/firestore-compatibility-layer";
import {AuthenticationError, IUserData, PlanEpoch, UsernameTakenError} from "@buildwithflux/models";
import {isEducationalEmail} from "@buildwithflux/plans";
import {Logger, Unsubscriber} from "@buildwithflux/shared";

import {UseFeatureFlagsStore} from "../../common/hooks/featureFlags/useFeatureFlag";

import {AuthService} from "./auth";

export interface SignupSuccess {
    firebaseUser: FirebaseUser;
    user: IUserData;
    userWasCreated: boolean;
}

interface UserCreationFeatureFlags {
    newPlansForNewUsers: boolean;
}

/**
 * Service for creating user accounts
 *
 * A signUp is an operation that involves:
 *  - performFirebaseAuthenticationIfNeeded: An optional authentication step, where the user is logged in using Firebase Auth (linking any existing anonymous account on the way)
 *    - This step is skipped if the user is already logged in to Firebase Auth with a non-anonymous account
 *  - completeSignUpForAuthenticatedFirebaseUser: A transactional Firestore write, where the user's profile is created
 *    - This step is skipped if the user already has a profile in Firestore
 *    - The write involves 3 documents (users, usernames, usersPrivateMetadata), and one possible delete (old anonymous users document)
 */
export class SignUpService {
    private readonly featureUnsubscriber: Unsubscriber;
    private featureFlags: Partial<UserCreationFeatureFlags> = {};

    constructor(
        private readonly firebaseAuth: AuthService,
        private readonly firestoreAdapter: ClientFirestoreAdapter,
        featureFlagsStore: UseFeatureFlagsStore,
        private readonly logger: Logger,
    ) {
        this.updateFromFeatureFlagStore(featureFlagsStore.getState());
        this.featureUnsubscriber = featureFlagsStore.subscribe((flags) => {
            this.updateFromFeatureFlagStore(flags);
        });
    }

    public shutdown(): void {
        this.featureUnsubscriber();
    }

    /**
     * Always returns an authenticated Firebase user, ready for the next stage of registration
     */
    public async performFirebaseAuthenticationIfNeeded(loginMethod: LoginMethod): Promise<FirebaseUser> {
        let currentUser = this.firebaseAuth.getCurrentFirebaseUser();

        if (!currentUser || currentUser.isAnonymous) {
            const result = await this.firebaseAuth.getCredentialProvider(loginMethod, currentUser)();
            currentUser = result.user;
        }

        if (currentUser.isAnonymous) {
            // This should not happen, otherwise we failed to log in but didn't throw an error above
            throw new AuthenticationError("Failed to authenticate user during registration");
        }

        return currentUser;
    }

    /**
     * The return value indicates the user that was signed in, and whether their Flux user profile was created during
     * the current signUp call. This is useful for running one-time account-setup actions, such as cloning initial
     * documents.
     *
     * @param userHandle The handle that the user wants to use
     * @param firebaseUser The currently authenticated non-anonymous user
     */
    public async completeSignUpForAuthenticatedFirebaseUser(
        userHandle: string,
        firebaseUser: FirebaseUser,
    ): Promise<SignupSuccess> {
        // Outside of the transaction, we query the current flux profile by uid - cannot query inside transaction
        const currentFluxProfile = firstFromSnapshot(
            await this.firestoreAdapter.userCollection().where("uid", "==", firebaseUser.uid).get(),
            () => undefined,
        );

        if (currentFluxProfile) {
            if (currentFluxProfile.isAnonymous) {
                // An existing anonymous user! Migrate their profile

                return {
                    firebaseUser,
                    user: await this.completeRegistrationByMigratingAnonymousUser(
                        firebaseUser,
                        currentFluxProfile,
                        userHandle,
                    ),
                    userWasCreated: true,
                };
            }

            // We successfully logged in during the registration process
            // But we should ignore the user's attempt to sign up again on an existing profile
            this.logger.debug("User was already logged in during registration attempt");
            return {
                user: currentFluxProfile,
                firebaseUser,
                userWasCreated: false,
            };
        }

        // A brand new user! Finish writing their profile
        return {
            firebaseUser,
            user: await this.completeRegistrationForNewUser(firebaseUser, userHandle),
            userWasCreated: true,
        };
    }

    private updateFromFeatureFlagStore(flags: FeatureFlagValues) {
        this.featureFlags = {
            newPlansForNewUsers: flags.newPlansForNewUsers,
        };
    }

    /**
     * Formerly known as AuthenticationHelpers.makeNewUserDataWithPreviousUserData
     */
    private async completeRegistrationByMigratingAnonymousUser(
        currentUser: FirebaseUser,
        anonFluxUserProfile: IUserData,
        userHandle: string,
    ): Promise<IUserData> {
        this.logger.debug("Migrating existing anonymous Flux profile");

        const newUser = makeUserData({
            uid: currentUser.uid,
            handle: userHandle.toLowerCase(),
            email: currentUser.email ?? anonFluxUserProfile.email ?? undefined,
            full_name: currentUser.displayName ?? userHandle ?? "",
            isAnonymous: false,
            picture: currentUser.photoURL ?? anonFluxUserProfile.picture ?? "",
            sign_up_referrer: anonFluxUserProfile.sign_up_referrer ?? "",
            sign_up_page_referrer: anonFluxUserProfile.sign_up_page_referrer ?? "",
            documents_count: anonFluxUserProfile.documents_count ?? 0,
            parts_count: anonFluxUserProfile.parts_count ?? 0,
            star_count: anonFluxUserProfile.star_count ?? 0,
            created_at: anonFluxUserProfile.created_at ?? Date.now(),
            updated_at: Date.now(),
        });

        const user = await this.saveWithTransaction(
            {
                ...anonFluxUserProfile,
                ...newUser,
            },
            anonFluxUserProfile.handle,
        );
        await this.firebaseAuth.setUserHandleForFirebaseUser(currentUser, userHandle);

        return user;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public async completeRegistrationForNewUser(currentUser: FirebaseUser, userHandle: string): Promise<IUserData> {
        this.logger.debug("Creating brand new Flux profile");

        const newUser = makeUserData({
            uid: currentUser.uid,
            handle: userHandle.toLowerCase(),
            email: currentUser.email ?? undefined,
            full_name: currentUser.displayName ?? userHandle ?? "",
            isAnonymous: false,
            picture: currentUser.photoURL ?? "",
        });

        const user = await this.saveWithTransaction(newUser);
        await this.firebaseAuth.setUserHandleForFirebaseUser(currentUser, userHandle);

        return user;
    }

    private getCreationFlags(newUserEmail: string | undefined): IUserPrivateMetadata["creationFlags"] {
        const shouldUseNewPlans =
            // HACK: featureFlags are somehow not being set in the e2e test environment sometimes
            (this.featureFlags?.newPlansForNewUsers ?? true) && (!newUserEmail || !isEducationalEmail(newUserEmail));

        return {
            planEpoch: shouldUseNewPlans ? PlanEpoch.enum.from_202410 : PlanEpoch.enum.legacy,
        };
    }

    /**
     * We want to ensure two things here:
     *  - That any existing user profile at the same handle as our write has the same UID as our write operation
     *    - This will be violated if some other process just managed to steal the username we wanted
     *  - That there is no existing user profile with the same handle, and a different UID
     *    - No existing user profile for the same handle is fine
     *    - An existing user profile for the same handle, with the same UID is fine (if this were violated, Firebase rules would fail anyway)
     *
     * Because there's no locking of rows that don't exist, we check both the user and handleMapping collections,
     * and perform a write in-between
     *
     * @param data The incoming user profile we are trying to write
     * @param existingAnonymousUserToRemoveHandle Handle of an existing anonymous user to remove, if any
     */
    private async saveWithTransaction(
        data: IUserData,
        existingAnonymousUserToRemoveHandle?: string,
    ): Promise<IUserData> {
        this.logger.debug("Beginning transaction");
        return await this.firestoreAdapter.runTransaction(async (t): Promise<IUserData> => {
            const {handle, uid} = data;

            const user = this.firestoreAdapter.user(handle);
            const anonUser = existingAnonymousUserToRemoveHandle
                ? this.firestoreAdapter.user(existingAnonymousUserToRemoveHandle)
                : undefined;
            const handleMapping = this.firestoreAdapter.handleMapping(handle);
            const userPrivateMetadata = this.firestoreAdapter.userPrivateMetadata(uid);

            const existingUserProfileByHandle = (await t.get(user)).data();
            const existingHandleMapping = (await t.get(handleMapping)).data();
            const existingAnonUserProfileByHandle = anonUser ? (await t.get(anonUser)).data() : undefined;

            // Indicates this username has been grabbed since the check in createRegistrationFlow()
            if (
                (existingUserProfileByHandle && existingUserProfileByHandle.uid !== uid) ||
                (existingHandleMapping && existingHandleMapping.user_uid !== uid)
            ) {
                throw new UsernameTakenError();
            }

            // Indicates something has changed since the check in createRegistrationFlow()
            if (existingAnonUserProfileByHandle && !existingAnonUserProfileByHandle.isAnonymous) {
                throw new AuthenticationError(
                    "Logic violation in transaction: did not expect non-anonymous user profile as previous anonymous user",
                );
            }

            if (existingUserProfileByHandle) {
                this.logger.debug("Prepared to overwrite existing user profile", existingUserProfileByHandle);
            } else {
                this.logger.debug("No existing user profile to overwrite", existingUserProfileByHandle);
            }

            if (existingAnonymousUserToRemoveHandle && existingAnonymousUserToRemoveHandle !== handle) {
                this.logger.debug("Will remove existing anonymous data", existingAnonymousUserToRemoveHandle);
                t.delete(this.firestoreAdapter.user(existingAnonymousUserToRemoveHandle));

                // No need to delete their handleMapping, because anonymous users do not usually have a handleMapping/record in the usernames collection
                // No need to delete their userPrivateMetadata, because that collection is keyed by Firebase User ID, not handle, so will be overwritten already above
            }

            t.set(user, data);
            t.set(handleMapping, {
                user_uid: uid,
                userHandle: handle,
                organizationUid: undefined,
            });
            t.set(userPrivateMetadata, {uid, creationFlags: this.getCreationFlags(data.email)}, {merge: true});

            return data;
        });
    }
}
